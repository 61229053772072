import { Row, Col, Tooltip, Progress } from "antd";
import moment from "moment";
//interests icons
import Academic from '../../../../../assets/images/icons/pivot-admin/Interests/icon-academic.svg'
import Arts from '../../../../../assets/images/icons/pivot-admin/Interests/icon-arts.svg'
import Business from '../../../../../assets/images/icons/pivot-admin/Interests/icon-business.svg'
import Fairs from '../../../../../assets/images/icons/pivot-admin/Interests/icon-fairs.svg'
import Faith from '../../../../../assets/images/icons/pivot-admin/Interests/icon-faith.svg'
import HealthCounseling from '../../../../../assets/images/icons/pivot-admin/Interests/icon-health&counseling.svg'
import HumanitiesAcademic from '../../../../../assets/images/icons/pivot-admin/Interests/icon-humanities-academic.svg'
import PoliticsGovt from '../../../../../assets/images/icons/pivot-admin/Interests/icon-politics&govt.svg'
import RecActs from '../../../../../assets/images/icons/pivot-admin/Interests/icon-recreational-activities.svg'
import ServiceOutreach from '../../../../../assets/images/icons/pivot-admin/Interests/icon-service&outreach.svg'
import Sports from '../../../../../assets/images/icons/pivot-admin/Interests/icon-sports.svg'
import StemAcadEvents from '../../../../../assets/images/icons/pivot-admin/Interests/icon-stem-academic-events.svg'
import UnivEvents from '../../../../../assets/images/icons/pivot-admin/Interests/icon-university-events.svg'

const Event=({data}:any)=>{
    const total = data.reduce((accumulator:any, currentValue:any) => accumulator + currentValue.noOfEvents, 0,)
    const InterestsIcons=[
        {name:'Academic', icon:Academic},
        {name:'Arts', icon:Arts},
        {name:'Business', icon:Business},
        {name:'Fairs', icon:Fairs},
        {name:'Faith', icon:Faith},
        {name:'Health', icon:HealthCounseling},
        {name:'Humanities', icon:HumanitiesAcademic},
        {name:'Politics', icon:PoliticsGovt},
        {name:'Recreational', icon:RecActs},
        {name:'Service', icon:ServiceOutreach},
        {name:'Sports', icon:Sports},
        {name:'Stem', icon:StemAcadEvents},
        {name:'University', icon:UnivEvents},
    ]
    return(
        data?.map((s:any)=>
            <div className="data-container">
                <div className="half">
                    {InterestsIcons.some(e => e.name === ((s?.name).split(" ")[0]))&&(
                        <img src={InterestsIcons.filter(i=>i?.name===((s?.name).split(" ")[0]))[0].icon} className="grey-sq"/>
                    )}
                        <Tooltip title={s?.name}>
                            <p className="name">
                                {s?.name}
                            </p>
                        </Tooltip>
                    </div>
                <div className="half">
                <Progress percent={((s?.noOfEvents/total)*100)} showInfo={false} strokeColor={"#003888"} style={{marginRight:'10px'}}/>
                {((s?.noOfEvents/total)*100).toPrecision(3)}%
                </div>
            </div>
        )
    )
}

export default Event;
