import { Fragment, useState, useEffect } from "react";
import {
  Button,
  Modal,
  Tooltip,
  message,
  Form,
  Divider,
  Tag,
  Space,
  Avatar,
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import {
  ExclamationCircleOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  acceptEventInvite,
  declineEventInvite,
  deleteEvent,
} from "../../actions/event";
import ModalDeclineReason from "../member-app/Modals/ModalDeclineReason";
import { OK, NO_CONTENT } from "../../helpers/statuscodes";
import Calendar from "../../assets/images/icons/calendar-blue.svg";
import MapPin from "../../assets/images/icons/map-pin-blue.svg";
import Target from "../../assets/images/icons/target-blue.svg";
import Category from "../../assets/images/icons/category.svg";
import {
  createCalendarEvent,
  deleteCalendarEvent,
} from "../../actions/calendar";
import Contacts from "../../assets/images/icons/user-check-b.svg";
import { contactsColors } from "../../helpers/contactsColors";
import ModalContacts from "./ModalContacts";
import { createEventViewTrail } from "../../actions/analytics";

const ModalEventView = (props: {
  acceptEventInvite: Function;
  declineEventInvite: Function;
  deleteEvent: Function;
  refresh: Function;
  eventData: any;
  eventTags: any;
  conflicts: any;
  isVisible: boolean;
  setIsVisible: Function;
  onModalCancelHandler: any;
  selectedTime: any;
  googleAccessToken: string;
  createCalendarEvent: Function;
  deleteCalendarEvent: Function;
  onEventModify: Function;
  allEvents: any;
  createEventViewTrail: Function;
}) => {
  const {
    acceptEventInvite,
    declineEventInvite,
    eventData,
    eventTags,
    conflicts,
    isVisible,
    setIsVisible,
    deleteEvent,
    onModalCancelHandler,
    refresh,
    selectedTime,
    googleAccessToken,
    createCalendarEvent,
    deleteCalendarEvent,
    onEventModify,
    allEvents,
    createEventViewTrail,
  } = props;
  const [hasInvite, setHasInvite] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isDeclineEventModalOpen, setIsDeclineEventModalOpen] = useState(false);
  const [isLoadingDeclineEventInvite, setIsLoadingDeclineEventInvite] =
    useState(false);
  const [declineEventForm] = Form.useForm();
  const [isAttended, setIsAttended] = useState(false);
  const [isModifyCalendarLoading, setIsModifyCalendarLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);
  let isUpcomingEvent = moment(
    eventData?.actualStart ?? eventData?.start
  ).isAfter(new Date());

  const onBack = () => {
    setIsVisible(true);
    setContacts([]);
    setIsContactsModalOpen(false);
  };

  const onViewContacts = (contacts: any) => {
    onModalCancelHandler();
    setContacts(
      contacts.map((e: any) => ({
        name: e.displayName,
        image: e.profilePicture,
      }))
    );
    setIsContactsModalOpen(true);
  };

  const ConflictTooltip = () => {
    return (
      <div className="custom-tooltip">
        {
          <>
            <h3 className="conflict-h3">Conflict</h3>
            <p>
              Event has conflict{conflicts?.conflicts?.length > 1 && "s"} with{" "}
              {conflicts?.conflicts?.map(
                (d: any, i: number) =>
                  `${d}${
                    conflicts?.conflicts?.length === i + 2
                      ? ", and "
                      : conflicts?.conflicts?.length === i + 1
                      ? ""
                      : ", "
                  } `
              )}
            </p>
          </>
        }
      </div>
    );
  };

  const acceptInvite = async () => {
    if (eventData?.userInvite !== undefined) {
      setIsAcceptLoading(true);
      const result = await acceptEventInvite({
        eventId: eventData?.eventId,
        inviteId: eventData?.userInvite?.inviteId,
      });

      setIsAcceptLoading(false);
      if (result && result?.data) {
        message.success("Invite Accepted");
        refresh();
        onModalCancelHandler();
      } else {
        message.error("An Error occured!");
      }
    }
  };

  const declineInvite = async (formData: any) => {
    setIsLoadingDeclineEventInvite(true);
    let values = {
      ...formData,
      eventName: eventData?.title,
      eventId: eventData?.eventId,
      inviteId: eventData?.userInvite?.inviteId,
    };

    const result = await props.declineEventInvite(values);
    setIsLoadingDeclineEventInvite(false);

    if (result.status == OK) {
      declineEventForm.resetFields();
      message.success(
        `You have successfully submitted decline reason for ${eventData?.title} invitation.`
      );
      refresh();
      setIsDeclineEventModalOpen(false);
      onModalCancelHandler();
    } else {
      message.error("An Error occured!");
    }
  };

  const onDeleteEvent = (eventId: string, eventName: string) => {
    Modal.confirm({
      title: "Delete Event",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete "${eventName}" event?`,
      okText: "Confirm",
      onOk: () => submitDeleteEvent(eventId),
      okButtonProps: { shape: "round" },
      cancelText: "Cancel",
      cancelButtonProps: { shape: "round" },
    });
  };

  const submitDeleteEvent = async (eventId: string) => {
    setIsAcceptLoading(true);
    const response = await deleteEvent(eventId);
    setIsAcceptLoading(false);
    if (response.status === NO_CONTENT) {
      refresh();
      onModalCancelHandler();
    }
  };

  const recordEventTrail = async (eventId: string) => {
    await createEventViewTrail(eventId);
  };

  useEffect(() => {
    if (eventData !== undefined) {
      if (
        eventData?.userInvite?.status === 0 &&
        eventData?.userInvite?.createdBy !== eventData?.userInvite?.memberId &&
        eventData?.userInvite?.inviteId !== undefined
      ) {
        setHasInvite(true);
      } else {
        setHasInvite(false);
      }
      if (isVisible) {
        recordEventTrail(eventData.eventId);
      }
    }
  }, [isVisible]);

  const club = eventData?.metadata?.fields.find(
    (f: any) => f.name === "clubLogin"
  ).value;
  const eventUrl = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventUrl"
  )?.value;
  const location = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventLocation"
  )?.value;
  const bannerUrl = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventPicture"
  )?.value;
  const eventPic =
    bannerUrl && bannerUrl.startsWith("http")
      ? bannerUrl
      : bannerUrl
      ? `https://yaleconnect.yale.edu${
          eventData?.metadata?.fields.find(
            (f: any) => f.name === "eventPicture"
          )?.value
        }`
      : null;

  return (
    <>
      <ModalContacts
        visible={isContactsModalOpen}
        onCancel={() => setIsContactsModalOpen(false)}
        onBack={onBack}
        // contacts={eventData?.mutual ? [] : eventData?.mutual.map((e:any) => {
        //   return {
        //     name: e.displayName ?? ''
        //   }
        // })}
        contacts={contacts}
      />
      <ModalDeclineReason
        title="Enter Decline Reason"
        handleSubmit={declineInvite}
        isOpen={isDeclineEventModalOpen}
        setIsOpen={setIsDeclineEventModalOpen}
        form={declineEventForm}
        isLoading={isLoadingDeclineEventInvite}
      />
      <Modal
        title={
          window.innerWidth < 992
            ? ` ${moment(eventData?.start).format(
                "h A"
              )} Breeze Events -  ${moment(eventData?.start).format(
                "MMMM DD, YYYY"
              )}`
            : null
        }
        className="modal-event-item"
        centered
        visible={isVisible}
        onCancel={() => onModalCancelHandler()}
        footer={[
          !eventData?.isGoogleEvent &&
            eventData?.metadata !== null &&
            isUpcomingEvent && (
              <Button
                type={"primary"}
                className="attendance-btn"
                loading={isModifyCalendarLoading}
                style={{ borderRadius: 5 }}
                onClick={async () => {
                  try {
                    setIsModifyCalendarLoading(true);
                    const res = await createCalendarEvent({
                      accessToken: googleAccessToken,
                      eventId: eventData.eventId,
                    });
                    message.success(
                      `You successfully added ${eventData.title}!`
                    );
                    onModalCancelHandler();
                  } catch (e) {
                    message.error("An Error Occured!");
                  }
                  onEventModify();
                  setIsModifyCalendarLoading(false);
                }}
              >
                {isModifyCalendarLoading ? "" : "Add to Calendar"}
              </Button>
            ),

          eventData?.isGoogleEvent && eventData?.metadata !== null && (
            <Button
              // type={"primary"}
              className="attendance-btn"
              style={{ borderRadius: 5 }}
              loading={isModifyCalendarLoading}
              // onClick={()=>setIsAttended(!isAttended)}
              onClick={async () => {
                try {
                  setIsModifyCalendarLoading(true);

                  const isPreferredEvent =
                    eventData.location !== "breeze.school.event.preferred";

                  const eventCounterpart = allEvents.find(
                    (e: any) =>
                      eventData.title === e.title &&
                      ((isPreferredEvent &&
                        e.location === "breeze.school.event.preferred") ||
                        (!isPreferredEvent &&
                          e.location !== "breeze.school.event.preferred"))
                  );

                  const res = await deleteCalendarEvent({
                    accessToken: googleAccessToken,
                    googleEventId: isPreferredEvent
                      ? eventCounterpart.eventId
                      : eventData.eventId,
                    eventId: isPreferredEvent
                      ? eventData.eventId
                      : eventCounterpart
                      ? eventCounterpart.eventId
                      : "",
                  });
                  message.success(
                    `You successfully removed ${eventData.title}!`
                  );
                  onModalCancelHandler();
                } catch (e) {
                  message.error("An Error Occured!");
                }
                setIsModifyCalendarLoading(false);
                onEventModify();
              }}
            >
              {/* {isAttended?"No, I changed my mind":"Attend"} */}
              {isModifyCalendarLoading ? "" : "Remove from Calendar"}
            </Button>
          ),
          eventData?.metadata !== null && eventUrl && (
            <Button
              className="attendance-btn"
              style={{ borderRadius: 5 }}
              onClick={() =>
                window.open(
                  eventUrl && eventUrl.startsWith("http")
                    ? eventUrl
                    : `https://yaleconnect.yale.edu/${club}${eventUrl}`,
                  "_blank"
                )
              }
            >
              Visit Event Page
            </Button>
          ),
        ]}
      >
        <div className="event-view">
          {eventPic ? (
            <img className="image-banner" src={eventPic} />
          ) : (
            <div className="image-placeholder" />
          )}
          <div className="x-btn" />
          {selectedTime !== "" && (
            <div className="primetime-label">Primetime</div>
          )}
          <div className="content">
            <Tooltip title={eventData?.title}>
              <span className="title">
                <h2>{eventData?.title}</h2>
              </span>
            </Tooltip>
            <Tooltip title={eventData?.description}>
              <p className="grey-text description">{eventData?.description}</p>
            </Tooltip>
            <span className="info-point mt-2">
              <div className="icon-square">
                <CalendarOutlined />
              </div>
              <p>
                {moment(eventData?.actualStart ?? eventData?.start).format(
                  "D MMMM YYYY"
                )}{" "}
                {moment(eventData?.actualStart ?? eventData?.start).format(
                  "h:mm a"
                )}{" "}
                -{" "}
                {moment(eventData?.actualStart ?? eventData?.start).isSame(
                  eventData?.actualEnd ?? eventData?.end,
                  "day"
                )
                  ? moment(eventData?.actualEnd ?? eventData?.end).format(
                      "h:mm a"
                    )
                  : moment(eventData?.actualEnd ?? eventData?.end).format(
                      "MMM D, h:mm a"
                    )}
              </p>
            </span>
            <span className="info-point">
              <div className="icon-square">
                <EnvironmentOutlined />
              </div>
              <p>
                {location ? location.trim() ?? "No Location" : "No Location"}
              </p>
            </span>
            <span className="info-point">
              <div
                style={{
                  cursor:
                    eventData?.mutual && eventData?.mutual.length > 0
                      ? "pointer"
                      : "",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (eventData?.mutual && eventData?.mutual.length > 0)
                    onViewContacts(eventData?.mutual);
                }}
              >
                {eventData?.mutual && eventData?.mutual.length > 0 ? (
                  <>
                    <Avatar.Group
                      maxCount={3}
                      // maxPopoverTrigger="click"
                      maxPopoverTrigger="hover"
                      size="default"
                      maxStyle={{
                        color: "#0098EE",
                        backgroundColor: "#D3F4FF",
                        cursor: "pointer",
                        pointerEvents: "none",
                      }}
                      className="avatar-group"
                    >
                      {eventData?.mutual?.map((s: any) => (
                        <Tooltip title={s.displayName} placement="top">
                          <Avatar
                            style={{
                              backgroundColor:
                                contactsColors[Math.floor(Math.random() * 6)],
                            }}
                            src={s.profilePicture ?? null}
                          >
                            {s.displayName.match(/\b(\w)/g).join("")}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </Avatar.Group>
                    <p className="names">
                      {eventData?.mutual
                        ?.slice(0, 3)
                        .map((n: any) => `${n.displayName}, `)}{" "}
                      {eventData?.mutual?.length > 3 &&
                        `and {eventData?.mutual?.length - 3} others`}
                    </p>
                  </>
                ) : (
                  <>
                    <div className="icon-square">
                      <UserOutlined />
                    </div>
                    <span>No mutual(s) attending</span>
                  </>
                )}
              </div>
            </span>
            <div className="tag-area">
              {window.innerWidth > 992 && eventData?.eventTagIds !== null && (
                <Space size={[0, 8]} wrap>
                  {eventData?.eventTagIds?.map((t: any) => (
                    <Tag className="tag" key={t}>
                      {
                        eventTags.filter((tag: any) => tag.eventTagId === t)[0]
                          ?.name
                      }
                    </Tag>
                  ))}
                </Space>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: { auth: any; googleAccessToken: string }) => ({
  googleAccessToken: state.auth?.googleAccessToken,
});
export default connect(mapStateToProps, {
  acceptEventInvite,
  declineEventInvite,
  deleteEvent,
  createCalendarEvent,
  deleteCalendarEvent,
  createEventViewTrail,
})(ModalEventView);
