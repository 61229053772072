import { ReactNode, useState, useEffect } from "react";
import {
  Button,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";

const WeekDaySelector = (props: {
    dateRange: any;
    setDate: Function;
}) => {
  const { dateRange, setDate } = props;

  const getThisWeekDates =()=> {
    var weekDates= []; 
    for (var i = 1; i <= (moment(dateRange).endOf('month').date()); i++) {
      weekDates.push(moment(dateRange).date(i)); 
    }

    return weekDates; 
  }
  
  var thisWeekDates = getThisWeekDates();

  useEffect(()=>{
    document.getElementById("selected")?.scrollIntoView({behavior: "smooth", inline: "center", block: 'nearest' });
  },[dateRange])

  return (
    <div className="week-day-selector">
        <Button 
          onClick={()=>setDate(moment(dateRange, "YYYY MM DD").subtract(1,"month").endOf('month'))}
          className={`day-arrow`}
          >{"<"}</Button>
        {thisWeekDates.map(d=>
            <Button 
                onClick={()=>setDate(moment(d, "YYYY MM DD").toISOString())}
                className={`day-btn ${d.format("ddd DD") === moment(dateRange).format("ddd DD") && 'selected'}`}
                id={d.format("ddd DD") === moment(dateRange).format("ddd DD") ? 'selected':moment(dateRange).format("DD")}
                >
                <h3>
                {d.format("ddd")}
                </h3>
                <h4>
                {d.format("DD")}
                </h4>
            </Button>
        )}
        <Button 
          onClick={()=>setDate(moment(dateRange, "YYYY MM DD").add(1,"month").startOf('month'))}
          className={`day-arrow`}
        >{">"}</Button>
    </div>
  );
};


export default connect()(WeekDaySelector);
