import { Fragment, useEffect, useRef, useState } from "react";
import type { CalendarMode } from "antd/es/calendar/generateCalendar";
import type { Moment } from "moment";
import PageHeader from "../layouts/PageHeader";
import userBadgeIcon from "../../../assets/images/icons/user-badge.svg";
import { connect } from "react-redux";
import {
  changePassword,
  getMyClubs,
  getMyEvents,
  getMyClubsGrouped,
  getProfileSummary,
  updateProfile,
  uploadProfilePicture,
} from "../../../actions/profile";

import { MyProfileSummary } from "../../../interface/Profile";
import DefaultAvatar from "../../widget/DefaultAvatar";
import { softSkillColors } from "../../../helpers/softSkillColors";
import { neutralSkillColors } from "../../../helpers/neutralSkillColors";
import BasicProfileDetails from "./AccountProfile";
import ChangeProfilePictureModal from "../../common/Modal/ChangeProfilePictureModal";
import ChangePasswordModal from "../../common/Modal/ChangePasswordModal";
import UpdateProfileModal from "../../common/Modal/UpdateProfileModal";

const Profile = (props: {
  auth: any;
  updateProfile: Function;
  changePassword: Function;
  uploadProfilePicture: Function;
  getProfileSummary: Function;
  getMyClubs: Function;
  getMyEvents: Function;
  getMyClubsGrouped: Function;
  flutterConnect: Boolean;
}) => {
  const {
    auth,
    getProfileSummary,
    getMyEvents,
    getMyClubsGrouped,
    flutterConnect
  } = props;
  const { member } = auth;
  const [filter, setFilter] = useState(0);
  const [myProfileSummary, setMyProfileSummary] = useState<MyProfileSummary>();
  const [myProfilePoints, setMyProfilePoints] = useState<[]>();
  const [myClubs, setMyClubs] = useState<any>();
  const [myClubsCount, setMyClubsCount] = useState<any>(0);
  const [myUpcomingEvents, setMyUpcomingEvents] = useState<any>();
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isChangeProfilePictureModalOpen, setIsChangeProfilePictureModalOpen] =
    useState(false);
  const [chartData, setChartData] = useState<any>();

  const getMyProfileSummary = async () => {
    const data = await getProfileSummary();
    let totalPoints = data.data.pointsBreakdown.reduce(function (
      sum: any,
      current: { earnedPoints: any }
    ) {
      return sum + parseFloat(current.earnedPoints);
    },
      0);
    let count = 0;
    let pointsPercentage = data.data.pointsBreakdown.map(
      (point: any, index: any) => {
        return {
          earnedPoints: ((point.earnedPoints / totalPoints) * 100).toFixed(2),
          earnedPointsFloat: point.earnedPoints.toFixed(2),
          softskill: point.softskill,
          softskillId: point.softskillId,
          color:
            softSkillColors[index] ??
            neutralSkillColors[index % neutralSkillColors.length],
        };
      }
    );

    setMyProfilePoints(pointsPercentage);

    setChartData({
      labels: pointsPercentage.map((item: any) => {
        return item.softskill;
      }),
      datasets: [
        {
          label: "Soft Skills Distribution",
          data: pointsPercentage.map((item: any) => {
            return item.earnedPoints;
          }),
          backgroundColor: pointsPercentage.map((item: any) => {
            return item.color;
          }),
          hoverOffset: 4,
        },
      ],
    });
    setMyProfileSummary(data.data);
  };

  const fetchMyClubs = async () => {
    let params = {
      searchString: "",
      privacyTypes: "",
      interests: "",
    };
    let res = await getMyClubsGrouped(params);
    setMyClubsCount(res.data[0]?.count);
    setMyClubs(res.data[0]?.clubs.slice(0, 7));
  };

  const fetchMyUpcomingEvents = async () => {
    let result = await getMyEvents({
      page: 1,
      pageSize: 2,
    });
    setMyUpcomingEvents(result.data.items);
  };

  useEffect(() => {
    getMyProfileSummary();
    fetchMyClubs();
    fetchMyUpcomingEvents();
  }, []);



  // useEffect(() => {
  //   if (location.pathname.includes("/settings")) {
  //     setFilter(3);
  //   } else {
  //     setFilter(0);
  //   }
  // }, [location.pathname]);

  return (
    <Fragment>
      <UpdateProfileModal isOpen={isEditProfileModalOpen} setIsOpen={setIsEditProfileModalOpen} />
      <ChangePasswordModal isOpen={isChangePasswordModalOpen} setIsOpen={setIsChangePasswordModalOpen} />
      <ChangeProfilePictureModal isOpen={isChangeProfilePictureModalOpen} setIsOpen={setIsChangeProfilePictureModalOpen} />
      <PageHeader
        className="pb-4"
        title={
          <div className="d-flex profile-header-container">
            {member.profilePicture ? (
              <div className="profile-picture-div">
                <div className="profile-avatar">
                  <img
                    src={member.profilePicture}
                    height="auto"
                    width="auto"
                  />
                </div>
              </div>
            ) : (
              <div className="profile-picture-div">
                <DefaultAvatar
                  firstName={member.firstName}
                  lastName={member.lastName}
                  size={79}
                />
              </div>
            )}

            <div className="profile-header-details">
              <h3>
                {member.firstName} {member.lastName}
              </h3>
              <div className="more-detail">
                <div>
                  <img src={userBadgeIcon} />
                </div>
                <h5>{member.memberType}</h5>
              </div>
            </div>
          </div>
        }
      >
      </PageHeader>
      <BasicProfileDetails flutterConnect={flutterConnect} member={member} />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  flutterConnect: state.flutterConnect
});

export default connect(mapStateToProps, {
  updateProfile,
  changePassword,
  uploadProfilePicture,
  getProfileSummary,
  getMyClubs,
  getMyEvents,
  getMyClubsGrouped,
})(Profile);
