import { connect } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { clearAuth, loadUser, logout, updateTokens } from "../../actions/auth";
import iconExclamation from "../../assets/images/icons/icon-exclamation.svg";
import { Button, Dropdown, Layout, Menu, Modal, Spin } from "antd";
import {
  UserOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  BellOutlined,
  MenuOutlined,
  DownOutlined,
} from "@ant-design/icons";

import Dashboard from "./dashboard/Dashboard";
import SchoolAdmin from "./school-admin/SchoolAdmin";
import { Fragment, useEffect, useState } from "react";
import AddUserBulk from "./school-admin/AddUserBulk";
import AddUser from "./school-admin/AddUser";
import { hideBlurEffect2, showBlurEffect2 } from "../../layouts/BlurEffect";
import SchoolAdminList from "./school-admin/SchoolAdminList";
import StudentList from "./student/StudentList";
import AddStudent from "./student/AddStudent";
import AddStudentBulk from "./student/AddStudentBulk";
import TeacherList from "./teacher/TeacherList";
import AddTeacher from "./teacher/AddTeacher";
import AddTeacherBulk from "./teacher/AddTeacherBulk";
import InterestList from "./interest/InterestList";
import { axiosInterceptors } from "../../utils/axiosInterceptors";
import SidebarMenu from "../layout/SidebarMenu";
import ClubList from "./club/ClubList";
import AddSoftSkill from "./softskill/AddSoftSkill";
import AddInterest from "./interest/AddInterest";
import SoftskillList from "./softskill/SoftskillList";
import AddClub from "./club/AddClub";
import Profile from "./profile/Profile";
import EventList from "./event/EventList";
import AddEvent from "./event/AddEvent";
import LocationList from "./location/LocationList";
import AddLocation from "./location/AddLocation";
import EventCalendar from "./event/EventCalendar";
import ClubMemberList from "./club/ClubMemberList";
import AddClubMember from "./club/AddClubMember";
import ClubEventList from "./club/ClubEventList";
import ModalLogout from "../layout/ModalLogout";
import AddEventSuccess from "./event/AddEventSuccess";
import EventView from "./event/EventView";
import ClubPage from "./club/ClubPage";
import NotificationList from "../member-app/notifications/NotificationList";
import AdminNotifications from "./notifications/AdminNotifications";
import SchoolList from "./schools/SchoolList";
import AddSchool from "./schools/AddSchool";
import SchoolYearList from "./school-year/SchoolYearList";
import SchoolYearPage from "./school-year/SchoolYearPage";
import DefaultAvatar from "../widget/DefaultAvatar";
import PrivacyPolicy from "./privacy-policy/PrivacyPolicy";
import AnnouncementList from "./announcements/AnnouncementList";
import AddAnnouncement from "./announcements/AddAnnouncement";
import breezeHorizontalLogoBlue from "../../assets/images/logos/breeze-horizontal-blue.svg";
import ImportEvent from "./event/ImportEvent";
import Poorvu from "./pivot/Poorvu";
import OPAC from "./pivot/OPAC";
import DateFilter from "./pivot/components/DateFilter";

const { Header, Sider, Content } = Layout;

const App = (props: any) => {
  const [initializing, setInitializing] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleVisibleChange = (flag: boolean) => {
    setDropdownVisible(flag);
  };
  const location = useLocation();

  const { auth } = props;
  const {
    auth: { isAuthenticated, displayName, member, loading, token },
    logout,
    loadUser,
    clearAuth,
    updateTokens,
    flutterConnect,
  } = props;
  const [modal2Visible, setModal2Visible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [ selectedDates, setDates ] = useState([null,null]);
  const [ filterType, setFilterType ] = useState('Month');  //dateFilterType
  // const flutterConnect = true;

  useEffect(() => {
    // const triggerInterceptor = async () => {
    //   await axiosInterceptors(auth, clearAuth, updateTokens);
    setInitializing(false);
    // };
    // triggerInterceptor();
    // loadUser();
  }, [auth]);

  const onLogoutHandler = () => {
    showBlurEffect2();
    setModal2Visible(true);
  };

  const onLogoutCancel = () => {
    hideBlurEffect2();
    setModal2Visible(false);
  };

  const handleHideNotification = () => {
    setDropdownVisible(false);
  };

  return initializing ? (
    <div className={`d-flex justify-content-center w-100 h-100`}>
      <div className="my-auto">
        <Spin />
      </div>
    </div>
  ) : (
    <Layout
      className={`AppMainLayout ${flutterConnect ? "flutter" : ""}`}
      id="component-layout-sidebar-custom-trigger"
    >
      {/* <AlertPop /> */}
      <ModalLogout
        onLogoutCancel={onLogoutCancel}
        modal2Visible={modal2Visible}
        logout={logout}
      />
      <SidebarMenu
        onLogout={onLogoutHandler}
        token={token}
        flutterConfig={flutterConnect
          ? {
            isOpen: isSidebarOpen,
            onClose: () => {
              setIsSidebarOpen(false);
            }
          }
          : undefined} />

      <Layout className="site-layout">
        {
          flutterConnect && (
            <Header
              className="site-layout-background app-main-header"
              style={{ padding: 0 }}
            >
              <div
                className="logo"
                style={{ backgroundImage: `url(${breezeHorizontalLogoBlue})` }}
              />
              <div className="action-buttons">
                <Menu
                  className="app-main-header-nav"
                  style={{ justifyContent: "right" }}
                  mode="horizontal"
                // defaultSelectedKeys={["2"]}
                >
                  <Menu.Item
                    key="notif-dropdown"
                    {...(dropdownVisible === false
                      ? {
                        onClick: () =>
                          setDropdownVisible(
                            location.pathname.indexOf("notifications") > 0
                              ? false
                              : true
                          ),
                      }
                      : "")}
                  >
                    <Dropdown
                      visible={dropdownVisible}
                      onVisibleChange={handleVisibleChange}
                      overlayClassName="notif-container"
                      overlay={
                        <NotificationList
                          fullWidth={false}
                          handleHideNotification={handleHideNotification}
                          dropdownVisible={dropdownVisible}
                        />
                      }
                      placement="bottomRight"
                      trigger={["click"]}
                    >
                      <BellOutlined style={{ color: "black" }} />
                    </Dropdown>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <div className="user-container">
                      <Link to={"/admin/profile"}>
                        {member && member.profilePicture ? (
                          <Fragment>
                            <img
                              src={member.profilePicture}
                              // src={member.profilePicture + "?" + Date.now()}
                              height={24}
                              width={24}
                            />{" "}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <DefaultAvatar firstName={displayName} lastName={""} />{" "}
                          </Fragment>
                        )}
                      </Link>
                    </div>
                  </Menu.Item>
                </Menu>
                <Button
                  className="sidebar-nav-button"
                  size="large"
                  icon={<MenuOutlined />}
                  onClick={() => setIsSidebarOpen(true)}
                />
              </div>
            </Header>

          )
        }

        {
          !flutterConnect && (
            <Header
              className="site-layout-background app-main-header"
              style={{ padding: 0 }}
            >
              <Menu
                className="app-main-header-nav"
                style={{ justifyContent: "right" }}
                mode="horizontal"
              // defaultSelectedKeys={["2"]}
              >
                {/* <Menu.Item key="1">
                  <SearchOutlined className="text-white" />
                </Menu.Item>
                <Menu.Item key="2">
                  <QuestionCircleOutlined className="text-white" />
                </Menu.Item>

                <Menu.Item
                  key="notif-dropdown"
                  {...(dropdownVisible === false
                    ? {
                      onClick: () =>
                        setDropdownVisible(
                          location.pathname.indexOf("notifications") > 0
                            ? false
                            : true
                        ),
                    }
                    : "")}
                >
                  <Dropdown
                    visible={dropdownVisible}
                    onVisibleChange={handleVisibleChange}
                    overlayClassName="notif-container"
                    overlay={
                      <NotificationList
                        fullWidth={false}
                        handleHideNotification={handleHideNotification}
                        dropdownVisible={dropdownVisible}
                      />
                    }
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <BellOutlined style={{ color: "white" }} />
                  </Dropdown>
                </Menu.Item> */}
                <Menu.Item key="1">
                    <DateFilter date={selectedDates} select={setDates} setFilterType={setFilterType}/>
                </Menu.Item>
                <Menu.Item key="4">
                  <div className="user-container">
                    <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="logout" icon={<LogoutOutlined />}>
                                <a onClick={() => onLogoutHandler()}>Logout</a>
                              </Menu.Item>
                            </Menu>
                          }
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                      {member && member.profilePicture ? (
                        <Fragment>
                          <img
                            src={member.profilePicture}
                            // src={member.profilePicture + "?" + Date.now()}
                            height={24}
                            width={24}
                          />{" "}
                          <h5>{member.firstName + " " + member.lastName}</h5>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <DefaultAvatar firstName={displayName} lastName={""} />{" "}
                          {member && member.firstName && member.lastName ? (
                            <h5>{member.firstName + " " + member?.lastName}</h5>
                          ) : (
                            <h5>{displayName}</h5>
                          )}
                        </Fragment>
                      )}
                      <DownOutlined style={{ color: "black", marginLeft:'5px' }} />
                      </a>
                    </Dropdown>
                  </div>
                </Menu.Item>
              </Menu>
            </Header>
          )
        }

        <Content
          id="adminpagecontainer"
          className="site-layout-background"
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <Routes>
            {/* <Route index={true} element={<Dashboard />} /> */}
            {member ? (
              // <Route index={true} element={<StudentList />} />
              <Route index={true} element={<Poorvu />} />
            ) : (
              <Route index={true} element={<SchoolAdminList />} />
            )}
            <Route path="/poorvu" element={<Poorvu dates={selectedDates} filterType={filterType}/>} />
            <Route path="/opac" element={<OPAC dates={selectedDates} filterType={filterType}/>} />
            {(window.location.href.includes("pivot-dev")||window.location.href.includes("pivot-stg")||window.location.href.includes("localhost") )&&
              <Route path="/import-event" element={<ImportEvent />} />
            }
            {/* <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/school" element={<SchoolAdmin />} />
            <Route path="/school-admins" element={<SchoolAdminList />} />
            <Route path="/school-admin/add-user" element={<AddUser />} />
            <Route
              path="/school-admin/add-user-bulk"
              element={<AddUserBulk />}
            />
            <Route path="/students" element={<StudentList />} />
            <Route path="/students/add" element={<AddStudent />} />
            <Route path="/students/add-bulk" element={<AddStudentBulk />} />
            <Route path="/teachers" element={<TeacherList />} />
            <Route path="/teachers/add" element={<AddTeacher />} />
            <Route path="/teachers/add-bulk" element={<AddTeacherBulk />} />
            <Route path="/interests/" element={<InterestList />} />
            <Route path="/interests/add" element={<AddInterest />} />
            <Route path="/softskills/" element={<SoftskillList />} />
            <Route path="/softskills/add" element={<AddSoftSkill />} />
            <Route path="/schoolyears" element={<SchoolYearList />} />
            <Route path="/schoolyears/add" element={<SchoolYearPage />} />
            <Route
              path="/schoolyears/:schoolYearId"
              element={<SchoolYearPage />}
            />
            <Route path="/clubs/" element={<ClubList />} />
            <Route path="/clubs/:id" element={<ClubMemberList />} />
            <Route path="/club/:memberGroupId" element={<ClubPage />} />
            <Route
              path="/clubs/:id/invite-members"
              element={<AddClubMember />}
            />
            <Route path="/clubs/:id/events" element={<ClubEventList />} />
            <Route path="/clubs/:id/events/edit" element={<AddEvent />} />
            <Route path="/clubs/add" element={<AddClub />} />
            <Route path="/events/" element={<EventList />} />
            <Route path="/events/calendar" element={<EventCalendar />} />
            <Route path="/events/add" element={<AddEvent />} />
            <Route path="/events/add-success" element={<AddEventSuccess />} />
            <Route path="/events/:eventId/" element={<EventView />} />

            <Route path="/locations/" element={<LocationList />} />
            <Route path="/locations/add" element={<AddLocation />} />
            <Route path="/notifications" element={<AdminNotifications />} />
            <Route path="/schools/" element={<SchoolList />} />
            <Route path="/schools/add" element={<AddSchool />} />
            <Route path="/announcements/" element={<AnnouncementList token={token} />} />
            <Route path="/announcements/add" element={<AddAnnouncement />} /> */}

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};
const mapStateToProps = (state: { auth: any, flutterConnect: any }) => ({
  auth: state.auth,
  flutterConnect: state.flutterConnect
});
export default connect(mapStateToProps, {
  logout,
  loadUser,
  clearAuth,
  updateTokens,
})(App);
