import { Button, Col, Row } from "antd"
import { Fragment } from "react"
import { Link } from "react-router-dom";
export interface IAccountProfile {
    flutterConnect: Boolean;
    member: any
}
const BasicProfileDetails: React.FC<IAccountProfile> = ({ flutterConnect, member }) => {

    return <Fragment>
        <div className="profile-page-single-content-wrapper">
            <div className="profile-page-single-content">

                <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                    <Col
                        className="gutter-row"
                        xs={{ span: flutterConnect ? undefined : 24 }}
                        sm={{ span: flutterConnect ? undefined : 12 }}
                        md={{ span: flutterConnect ? undefined : 12 }}
                        lg={{ span: flutterConnect ? undefined : 17 }}
                        xl={{ span: flutterConnect ? undefined : 17 }}
                        span={flutterConnect && 24}
                    >
                        <div className="content-left">
                            <h4 className="text-head"> Account Profile</h4>
                            <p>First Name: <b>{member.firstName}</b></p>
                            <p>Last Name: <b>{member.lastName}</b></p>
                            <p>Email: <b>{member.emailAddress}</b></p>
                            <Link to="/calendar" className="privacy-policy"><Button>Return to Calendar</Button></Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </Fragment>
}

export default BasicProfileDetails;