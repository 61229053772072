import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Col, PageHeader, Row, Spin, Divider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import {
    CalendarOutlined,
    RiseOutlined,
    FallOutlined,
} from "@ant-design/icons";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import PersonIcon from '../../../../assets/images/icons/pivot-admin/Person.svg'
import PersonAlertIcon from '../../../../assets/images/icons/pivot-admin/person_alert.svg'
import GroupIcon from '../../../../assets/images/icons/pivot-admin/group.svg'

import MonthFilter from "../components/MonthFilter";
import { getLoginOverview, getWeeklyLoginOverview } from "../../../../actions/pivotAdmin";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
)

const LoginsAnalytics=(props:{
    dates: any,
    filterType: any,
    getLoginOverview: Function,
    getWeeklyLoginOverview: Function,
})=>{
    const {
        dates,
        filterType,
        getLoginOverview,
        getWeeklyLoginOverview,
      } = props;
    const [ selectedMonth, setSelectedMonth ] = useState(moment().month()+1);
    const [ loginOverViewData, setLoginOverviewData ] = useState<any>({});
    const [ weeklyLoginData, setWeeklyLoginData ] = useState<any>([]);
    const [ activePercent, setActivePercent ] = useState({type:"",value:0});
    const [ inactivePercent, setInactivePercent ] = useState({type:"",value:0});
    const [ totalPercent, setTotalPercent ] = useState({type:"",value:0});
    const [ isFetching, setIsFetching ] = useState(false);

    const fetchLoginOverview = async() =>{
        setIsFetching(true);
        const defaultFrom = moment().startOf("month").utc().toISOString();
        const defaultTo = moment().endOf("month").utc().toISOString();
        const res = await getLoginOverview({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
            filterBy : filterType
        });
        if(res.status===200){
            setLoginOverviewData(res.data);
            getPercentageChange(res.data);
        }
        const res2 = await getWeeklyLoginOverview({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
        });
        if(res2.status===200){
            setWeeklyLoginData(res2.data);
        }
        setIsFetching(false);
    }

    useEffect(() => {
        fetchLoginOverview();
    }, [dates]);

    function toFixedIfNecessary(value:any){
        return +parseFloat(value).toFixed(0);
    }
    
    const getPercentageChange = (data:any) =>{
        if(data?.activeUsers>=data?.activeUsersLastWeek){
            setActivePercent({type:"increase",value:toFixedIfNecessary(((data?.activeUsers-data?.activeUsersLastWeek)/data?.activeUsersLastWeek)*100)});
        }else{
            setActivePercent({type:"decrease",value:toFixedIfNecessary(((data?.activeUsersLastWeek-data?.activeUsers)/data?.activeUsers)*100)});
        }

        if(data?.inactiveUsers>=data?.inactiveUsersLastWeek){
            setInactivePercent({type:"increase",value:toFixedIfNecessary(((data?.inactiveUsers-data?.inactiveUsersLastWeek)/data?.inactiveUsersLastWeek)*100)});
        }else{
            setInactivePercent({type:"decrease",value:toFixedIfNecessary(((data?.inactiveUsersLastWeek-data?.inactiveUsers)/data?.inactiveUsers)*100)});
        }
   
        if(data?.totalUsers>=data?.totalUsersLastWeek){
            setTotalPercent({type:"increase",value:toFixedIfNecessary(((data?.totalUsers-data?.totalUsersLastWeek)/data?.totalUsersLastWeek)*100)});
        }else{
            setTotalPercent({type:"decrease",value:toFixedIfNecessary(((data?.totalUsersLastWeek-data?.totalUsers)/data?.totalUsers)*100)});
        }
    }

    const [userData, setUserData] = useState({
        labels:[],
        datasets: [
            {
              label: "Active Users",
              data: weeklyLoginData.map((data:any) => data.activeUsers),
              backgroundColor: [
                "#02B8F6",
              ],
              borderColor: "#02B8F6",
              pointRadius:0,
              borderWidth: 2,
            },
            {
              label: "Inactive Users",
              data: weeklyLoginData.map((data:any) => data.inactiveUsers),
              backgroundColor: [
                "#912FC0",
              ],
              borderColor: "#912FC0",
              pointRadius:0,
              borderWidth: 2,
            },
          ],
    })

    useEffect(() => {
        if(weeklyLoginData.length!==0){
            setUserData({
                labels:weeklyLoginData.map((d:any)=>`${moment(d.fromDate).format('MM/DD/YY')}-${moment(d.toDate).format('MM/DD/YY')}`),
                datasets: [
                    {
                    label: "Active Users",
                    data: weeklyLoginData.map((data:any) => data.activeUsers),
                    backgroundColor: [
                        "#02B8F6",
                    ],
                    borderColor: "#02B8F6",
                    pointRadius:0,
                    borderWidth: 2,
                    },
                    {
                    label: "Inactive Users",
                    data: weeklyLoginData.map((data:any) => data.inactiveUsers),
                    backgroundColor: [
                        "#912FC0",
                    ],
                    borderColor: "#912FC0",
                    pointRadius:0,
                    borderWidth: 2,
                    },
                ],
            })
        }
    }, [weeklyLoginData]);

    return(
        <div className="component-container" style={{height:'45vh'}}>
            <div className="header">
                <h1 className="title">
                    AVERAGE LOGINS ANALYTICS
                </h1>
                <Spin className="spin-loading" spinning={isFetching}/>
            </div>
            <Divider className="divider"/>
            <div className="overview">
                <Row gutter={[16, 16]} >
                    <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <div className="section">
                            <div className="top">
                                <div className="white-sq">
                                    <img src={PersonIcon}/>
                                </div>
                                <div className="data">
                                    <h1>{loginOverViewData?.activeUsers}</h1>
                                    <p>ACTIVE USERS</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <span className={activePercent.type==="increase"?"blue":"red"}>{activePercent.type==="increase"?<RiseOutlined/>:<FallOutlined/>} {activePercent.type==="increase"?"+":"-"}{activePercent.value}% </span>
                                {activePercent.type==="increase"?"more":"less"} than last week
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <div className="section">
                            <div className="top">
                                <div className="white-sq">
                                    <img src={PersonAlertIcon}/>
                                </div>
                                <div className="data">
                                    <h1>{loginOverViewData?.inactiveUsers}</h1>
                                    <p>INACTIVE USERS</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <span className={inactivePercent.type==="increase"?"blue":"red"}>{inactivePercent.type==="increase"?<RiseOutlined/>:<FallOutlined/>} {inactivePercent.type==="increase"?"+":"-"}{inactivePercent.value}% </span>
                                {inactivePercent.type==="increase"?"more":"less"} than last week
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <div className="section">
                            <div className="top">
                                <div className="white-sq">
                                    <img src={GroupIcon}/>
                                </div>
                                <div className="data">
                                    <h1>{loginOverViewData?.totalUsers}</h1>
                                    <p>TOTAL USERS</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <span className={totalPercent.type==="increase"?"blue":"red"}>{totalPercent.type==="increase"?<RiseOutlined/>:<FallOutlined/>} {totalPercent.type==="increase"?"+":"-"}{totalPercent.value}% </span>
                                {totalPercent.type==="increase"?"more":"less"} than last week
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {(isFetching)?(
                    <Spin className="spin-loading" spinning={isFetching}/>
                ):(
            <div style={{height:'100%'}}>
                <Line 
                    data={userData} 
                    redraw 
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            myScale: {
                                position: 'right',
                            },
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: false,
                        },
                        responsive: true,
                        interaction: {
                        mode: 'index',
                        intersect: false,
                        },
                        plugins:{
                            legend:{
                                align:'start',
                                labels:{
                                    boxWidth:5,
                                    boxHeight:5,
                                    usePointStyle:true,
                                }
                            },
                            datalabels: {
                                display:false,
                            },
                            tooltip:{
                                // external:customTooltip,
                                enabled: false,
                                external: (context) => {
                                    // Tooltip Element
                                    let tooltipEl = document.getElementById('chartjs-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip';
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    const tooltipModel = context.tooltip;
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = '0';
                                        return;
                                    }

                                    // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
                                    tooltipEl.classList.remove('below', 'no-transform');


                                    // Set HTML & Data
                                    if (tooltipModel.body) {
                                        // console.log(tooltipModel)
                                        const Active = tooltipModel.dataPoints[0];
                                        const Inactive = tooltipModel.dataPoints[1];
                                        const innerHtml = `
                                        <div class="analytics-tooltip">
                                            <div class="title">
                                                ${tooltipModel.title}
                                            </div>
                                            <div class="total"><span>Total Users</span><span>${Number(Active.raw)+Number(Inactive.raw)}</span></div>
                                            <div class="point-info">
                                                <div class="left">
                                                    <div class="label">
                                                        <span class="point" style="background-color:${tooltipModel.labelColors[0].backgroundColor}"></span>
                                                        <span>Active User</span>
                                                    </div>
                                                    <p class="description">Users who already logged or added events from the last 2 days.</p>
                                                </div>
                                                <p class="point-total" style="color:${tooltipModel.labelColors[0].backgroundColor}">${Active.formattedValue}</p>
                                            </div>
                                            <div class="point-info">
                                                <div class="left">
                                                    <div class="label">
                                                        <span class="point" style="background-color:${tooltipModel.labelColors[1].backgroundColor}"></span>
                                                        <span>Inactive User</span>
                                                    </div>
                                                    <p class="description">Users with no activity from the last 2 days.</p>
                                                </div>
                                                <p class="point-total" style="color:${tooltipModel.labelColors[1].backgroundColor}">${Inactive.formattedValue}</p>
                                            </div>
                                        </div>
                                    `;

                                        tooltipEl.querySelector('table')!.innerHTML = innerHtml;
                                    }

                                    const position = context.chart.canvas.getBoundingClientRect();

                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = '1';
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                            }
                        },
                    }}
                    
                />
            </div>
                )}
            {/* <div className="construction">
                Oops! This feature is under construction.
            </div> */}
        </div>
    )
}

const mapStateToProps = (state: {
    auth: any;
    userIsBack: boolean;
    googleAccessToken: string;
  }) => ({
    userIsBack: state.userIsBack,
    googleAccessToken: state.auth?.googleAccessToken,
  });
export default connect(mapStateToProps, {
    getLoginOverview,
    getWeeklyLoginOverview,
})(LoginsAnalytics);
