import {
  Fragment,
  useState,
  useEffect,
  useRef,
  forwardRef,
  ForwardedRef,
} from "react";
import {
  Button,
  Modal,
  Tooltip,
  message,
  Form,
  Spin,
  Tag,
  Space,
  Avatar,
} from "antd";
import moment from "moment";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  CalendarOutlined,
  EnvironmentOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { contactsColors } from "../../helpers/contactsColors";

const EventItem = forwardRef(
  (props: any, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      data,
      selectedTime,
      allEvents,
      onModalCancelHandler,
      setContacts,
      setIsContactsModalOpen,
      googleAccessToken,
      createCalendarEvent,
      refresh,
      eventList,
      deleteCalendarEvent,
      setList,
    } = props;
    let event: any = data;
    const [eventLoadingId, setEventLoadingId] = useState("");
    let isPrimetime = false;
    let isUpcomingEvent = moment();
    const times = [
      { name: "Morning", start: "05:00:00", end: "11:59:59" },
      { name: "Afternoon", start: "12:00:00", end: "17:59:59" },
      { name: "Night", start: "18:00:00", end: "23:59:59" },
    ];
    const timeSlot = times.filter((t) => t.name == selectedTime)[0];
    if (timeSlot != undefined) {
      isPrimetime = moment(event?.start).isBetween(
        moment(timeSlot.start, "HH:mm:ss"),
        moment(timeSlot.end, "HH:mm:ss")
      );
    }

    const club = event?.metadata?.fields.find(
      (f: any) => f.name === "clubLogin"
    ).value;
    const eventUrl = event?.metadata?.fields.find(
      (f: any) => f.name === "eventUrl"
    )?.value;
    const location = event?.metadata?.fields.find(
      (f: any) => f.name === "eventLocation"
    )?.value;
    const bannerUrl = event?.metadata?.fields.find(
      (f: any) => f.name === "eventPicture"
    )?.value;
    const eventPic =
      bannerUrl && bannerUrl.startsWith("http")
        ? bannerUrl
        : bannerUrl
        ? `https://yaleconnect.yale.edu${
            event?.metadata?.fields.find((f: any) => f.name === "eventPicture")
              ?.value
          }`
        : null;
    const hasGoogleEvent =
      event?.hasGoogleEvent ||
      !!allEvents?.find(
        (e: any) =>
          e.location === "breeze.school.event.preferred" &&
          event.title === e.title
      );

    const onViewContacts = (contacts: any) => {
      onModalCancelHandler();
      setContacts(
        contacts.map((e: any) => ({
          name: e.displayName,
          image: e.profilePicture,
        }))
      );
      setIsContactsModalOpen(true);
    };

    return (
      <div className="list-event-item" ref={ref} data-eventId={event.eventId}>
        {eventPic ? (
          <img className="image-banner" src={eventPic} />
        ) : (
          <div className="image-placeholder" />
        )}
        {isPrimetime && <div className="primetime-label">Primetime</div>}
        <div className="content-mobile">
          <h3>{event?.title}</h3>
          <p className="text-group">
            <p className="grey-text">
              {moment(event?.start).format("h:mm A")} -{" "}
              {moment(event?.start).isSame(event?.end, "day")
                ? moment(event?.end).format("h:mm A")
                : moment(event?.end).format("MMM D, h:mm A")}
            </p>
          </p>
          <p>{event?.memberGroup ?? "No Club"}</p>
        </div>
        <div className="content-mobile-2">
          <div
            style={{
              cursor: event.mutual && event.mutual.length > 0 ? "pointer" : "",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => {
              if (event.mutual && event.mutual.length > 0)
                onViewContacts(event.mutual);
            }}
          >
            {event.mutual && event.mutual.length > 0 ? (
              <Avatar.Group
                maxCount={3}
                // maxPopoverTrigger="click"
                maxPopoverTrigger="hover"
                size={18}
                maxStyle={{
                  color: "#0098EE",
                  backgroundColor: "#D3F4FF",
                  cursor: "pointer",
                  pointerEvents: "none",
                }}
                className="avatar-group"
              >
                {event.mutual?.map((s: any) => (
                  <Tooltip title={s.displayName} placement="top">
                    <Avatar
                      style={{
                        backgroundColor:
                          contactsColors[Math.floor(Math.random() * 6)],
                      }}
                      src={s.profilePicture ?? null}
                    >
                      {s.displayName.match(/\b(\w)/g).join("")}
                    </Avatar>
                  </Tooltip>
                ))}
              </Avatar.Group>
            ) : (
              <span>No mutual(s)</span>
            )}
          </div>
          {!event.isGoogleEvent &&
            event.metadata !== null &&
            isUpcomingEvent && (
              <Spin
                spinning={event.eventId === eventLoadingId}
                wrapperClassName="event-list-spin"
              >
                <PlusCircleFilled
                  className="addIcon"
                  onClick={async () => {
                    try {
                      setEventLoadingId(event.eventId);
                      const res = await createCalendarEvent({
                        accessToken: googleAccessToken,
                        eventId: event.eventId,
                      });

                      if (res.status !== 200) {
                        message.error("An Error Occured!");
                        throw "Error creating event.";
                      }

                      message.success(`You successfully added ${event.title}!`);
                      setEventLoadingId("");
                      onModalCancelHandler();
                      refresh();
                    } catch (e) {
                      message.error("An Error Occured!");
                    }
                  }}
                />
              </Spin>
            )}
          {event.isGoogleEvent && event.metadata !== null && (
            <Spin
              spinning={event.eventId === eventLoadingId}
              wrapperClassName="event-list-spin"
            >
              <MinusCircleFilled
                className="subIcon"
                onClick={async () => {
                  try {
                    setEventLoadingId(event.eventId);

                    const fromAllEvents = allEvents.find(
                      (e: any) =>
                        e.location === "breeze.school.event.preferred" &&
                        event.title === e.title
                    ) as any;
                    const fromEventList = eventList.find(
                      (e: any) =>
                        e.location === "breeze.school.event.preferred" &&
                        event.title === e.title
                    ) as any;

                    const googleEventCounterpart =
                      fromAllEvents ?? fromEventList;

                    const res = await deleteCalendarEvent({
                      accessToken: googleAccessToken,
                      googleEventId: hasGoogleEvent
                        ? googleEventCounterpart?.eventId
                        : event.eventId,
                      eventId: hasGoogleEvent ? event.eventId : "",
                    });

                    if (res.status !== 200) {
                      message.error("An Error Occured!");
                      throw "Error deleting event.";
                    }

                    message.success(`You successfully removed ${event.title}!`);
                    onModalCancelHandler();
                  } catch (e) {
                    message.error("An Error Occured!");
                  }
                  setEventLoadingId("");
                  refresh();
                }}
              />
            </Spin>
          )}
        </div>
        <div className="content">
          <Tooltip title={event?.title}>
            <span className="title">
              <h3>{event?.title}</h3>
            </span>
          </Tooltip>
          <Tooltip title={event?.description}>
            <p className="grey-text description">{event?.description}</p>
          </Tooltip>
          <span className="info-point mt-2">
            <div className="icon-square">
              <CalendarOutlined />
            </div>
            <p>
              {moment(event?.start).format("D MMMM YYYY h:mm a")} -{" "}
              {moment(event?.start).isSame(event?.end, "day")
                ? moment(event?.end).format("h:mm a")
                : moment(event?.end).format("MMM D, h:mm a")}
            </p>
          </span>
          <span className="info-point">
            <div className="icon-square">
              <EnvironmentOutlined />
            </div>
            <Tooltip
              title={
                location ? location.trim() ?? "No Location" : "No Location"
              }
            >
              <p>
                {location ? location.trim() ?? "No Location" : "No Location"}
              </p>
            </Tooltip>
          </span>
          <span className="info-point">
            <div
              style={{
                cursor:
                  event?.mutual && event?.mutual.length > 0 ? "pointer" : "",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                if (event?.mutual && event?.mutual.length > 0)
                  onViewContacts(event?.mutual);
              }}
            >
              {event?.mutual && event?.mutual.length > 0 ? (
                <>
                  <Avatar.Group
                    maxCount={3}
                    // maxPopoverTrigger="click"
                    maxPopoverTrigger="hover"
                    size="default"
                    maxStyle={{
                      color: "#0098EE",
                      backgroundColor: "#D3F4FF",
                      cursor: "pointer",
                      pointerEvents: "none",
                    }}
                    className="avatar-group"
                  >
                    {event?.mutual?.map((s: any) => (
                      <Tooltip title={s.displayName} placement="top">
                        <Avatar
                          style={{
                            backgroundColor:
                              contactsColors[Math.floor(Math.random() * 6)],
                          }}
                          src={s.profilePicture ?? null}
                        >
                          {s.displayName.match(/\b(\w)/g).join("")}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </Avatar.Group>
                  <p className="names">
                    {event?.mutual
                      ?.slice(0, 3)
                      .map((n: any) => `${n.displayName}, `)}{" "}
                    {event?.mutual?.length > 3 &&
                      `and {eventData?.mutual?.length - 3} others`}
                  </p>
                </>
              ) : (
                <>
                  <div className="icon-square">
                    <UserOutlined />
                  </div>
                  <span>No mutual(s) attending</span>
                </>
              )}
            </div>
          </span>
          {/* <div className="tag-area">
              {event?.eventTagIds!==null && (
                  <Space size={[0, 8]} wrap>
                    {event?.eventTagIds?.map((t:any)=>
                    <Tag className="tag">
                      {(eventTags.filter((tag:any)=>tag.eventTagId===t)[0]?.name)}
                    </Tag>
                  )}
                  </Space>
              )}
            </div> */}
          <div
            style={{ display: "flex", justifyContent: "center", gap: "8px" }}
          >
            {event.isGoogleEvent && event.metadata !== null && (
              <Button
                className="attendance-btn"
                loading={event.eventId === eventLoadingId}
                style={{
                  borderRadius: 5,
                  width: hasGoogleEvent ? "150px" : "100%",
                }}
                onClick={async () => {
                  try {
                    setEventLoadingId(event.eventId);

                    const fromAllEvents = allEvents.find(
                      (e: any) =>
                        e.location === "breeze.school.event.preferred" &&
                        event.title === e.title
                    ) as any;
                    const fromEventList = eventList.find(
                      (e: any) =>
                        e.location === "breeze.school.event.preferred" &&
                        event.title === e.title
                    ) as any;

                    const googleEventCounterpart =
                      fromAllEvents ?? fromEventList;

                    const res = await deleteCalendarEvent({
                      accessToken: googleAccessToken,
                      googleEventId: hasGoogleEvent
                        ? googleEventCounterpart?.eventId
                        : event.eventId,
                      eventId: hasGoogleEvent ? event.eventId : "",
                    });

                    if (res.status !== 200) throw "Error deleting event.";

                    if (hasGoogleEvent) {
                      const tmpEvents: any = [...eventList];
                      tmpEvents[
                        eventList
                          .map((e: any) => e.eventId)
                          .indexOf(event.eventId)
                      ].hasGoogleEvent = false;
                      setList(
                        tmpEvents.filter(
                          (e: any) =>
                            !(
                              e.location === "breeze.school.event.preferred" &&
                              event.title === e.title
                            )
                        )
                      );
                      onModalCancelHandler();
                    } else {
                      setList(
                        eventList.filter(
                          (e: any) => e.eventId !== event.eventId
                        )
                      );
                    }
                    message.success(`You successfully removed ${event.title}!`);
                  } catch (e) {
                    message.error("An Error Occured!");
                  }
                  setEventLoadingId("");

                  refresh();
                }}
              >
                {/* {isAttended?"No, I changed my mind":"Attend"} */}
                {event.eventId === eventLoadingId ? "" : "Remove from Calendar"}
              </Button>
            )}
            {event.metadata !== null && ( //metadata only exists in yale scrape
              <>
                {!event.isGoogleEvent &&
                  event.metadata !== null &&
                  isUpcomingEvent && (
                    <Button
                      type="primary"
                      className="attendance-btn"
                      // disabled={isModifyCalendar}
                      loading={event.eventId === eventLoadingId}
                      style={{ borderRadius: 5, width: "150px" }}
                      onClick={async () => {
                        try {
                          setEventLoadingId(event.eventId);
                          const res = await createCalendarEvent({
                            accessToken: googleAccessToken,
                            eventId: event.eventId,
                          });

                          if (res.status !== 200) throw "Error creating event.";

                          const data = res.data;

                          const tmpEvents: any = [...eventList];
                          tmpEvents[
                            eventList
                              .map((e: any) => e.eventId)
                              .indexOf(event.eventId)
                          ].hasGoogleEvent = true;

                          tmpEvents.push({
                            eventId: data.eventId,
                            description: data.description,
                            end: moment(data.endDate),
                            start: moment(data.startDate),
                            isGoogleEvent: data.isGoogleEvent,
                            location: data.location,
                            title: data.title,
                            hidden: true,
                          });

                          setList(tmpEvents);
                          message.success(
                            `You successfully added ${event.title}!`
                          );
                          onModalCancelHandler();
                        } catch (e) {
                          message.error("An Error Occured!");
                        }
                        setEventLoadingId("");
                        refresh();
                      }}
                    >
                      {/* {isAttended?"No, I changed my mind":"Attend"} */}
                      {event.eventId === eventLoadingId
                        ? ""
                        : "Add to Calendar"}
                    </Button>
                  )}
                {event?.metadata !== null && eventUrl && (
                  <Button
                    type={"default"}
                    className="attendance-btn"
                    style={{ borderRadius: 5, width: "50%" }}
                    // onClick={()=>setIsAttended(!isAttended)}
                    onClick={() =>
                      window.open(
                        eventUrl && eventUrl.startsWith("http")
                          ? eventUrl
                          : `https://yaleconnect.yale.edu/${club}${eventUrl}`,
                        "_blank"
                      )
                    }
                  >
                    {/* {isAttended?"No, I changed my mind":"Attend"} */}
                    Visit Event Page
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);
export default EventItem;
