import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Col, PageHeader, Row, Spin, Divider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import SunIcon from '../../../../assets/images/icons/pivot-admin/sun.svg'
import SunBIcon from '../../../../assets/images/icons/pivot-admin/sun-blue.svg'
import ClockIcon from '../../../../assets/images/icons/pivot-admin/clock.svg'
import ClockBIcon from '../../../../assets/images/icons/pivot-admin/clock-blue.svg'
import InterestsIcon from '../../../../assets/images/icons/pivot-admin/Interests.svg'
import InterestsBIcon from '../../../../assets/images/icons/pivot-admin/Interests-blue.svg'

import Day from './Graphs/Day';
import Time from "./Graphs/Time";
import Interest from "./Graphs/Interest";
import { getEventsDaily, getPrimetime } from "../../../../actions/pivotAdmin";

const EventsAnalytics=(props:{
    dates: any,
    getEventsDaily: Function,
    getPrimetime: Function,
})=>{
    const {
        dates,
        getEventsDaily,
        getPrimetime,
      } = props;
    const [ selectedGraph, setSelectedGraph ] = useState(0);
    const [ DailyEventsAnalytics, setDailyEventsAnalytics ] = useState<any>([]);
    const [ PrimeTimeAnalytics, setPrimeTimeAnalytics ] = useState<any>([]);
    const [ prevWeek, setPrevWeek ] = useState<any>([]);
    const [ currWeekTop, setCurrWeekTop ] = useState<any>([]);
    const [ prevWeekTop, setPrevWeekTop ] = useState<any>([]);
    const [ currWeekInterest, setCurrWeekInterest ] = useState<any>("");
    const [ prevWeekInterest, setPrevWeekInterest ] = useState<any>("");
    const [ currWeekPrime, setCurrWeekPrime ] = useState<any>("");
    const [ prevWeekPrime, setPrevWeekPrime ] = useState<any>("");
    const [ isFetching, setIsFetching ] = useState(false);

    const fetchAnalytics = async() =>{
        setIsFetching(true);
        const defaultFrom = moment().startOf("month").utc().toISOString();
        const defaultTo = moment().endOf("month").endOf("week").add(1,"day").utc().toISOString();
        const res = await getEventsDaily({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?moment(dates[1]).endOf("week").add(1,"day").utc().toISOString():defaultTo,
        });
        if(res.status===200){
            setDailyEventsAnalytics(res?.data);
            setPrevWeek(res?.data?.slice(-14,-7));
        }
        const res2 = await getPrimetime({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?moment(dates[1]).endOf("week").add(1,"day").utc().toISOString():defaultTo,
        });
        if(res2.status===200){
            setPrimeTimeAnalytics(res2.data);
        }
        setIsFetching(false);
    }

    useEffect(() => {
        fetchAnalytics();
    }, [dates]);

    const Mode = (data:any) => {
        let dates = (data.map((d:any)=>moment(d?.date).format("hh:mm A")));
        var modes:any = [], count:any = [], i, number, maxIndex = 0;
 
        for (i = 0; i < dates.length; i += 1) {
            number = dates[i];
            count[number] = (count[number] || 0) + 1;
            if (count[number] > maxIndex) {
                maxIndex = count[number];
            }
        }
     
        for (i in count)
            if (count.hasOwnProperty(i)) {
                if (count[i] === maxIndex) {
                    modes.push(i);
                }
            }
     
        return (moment(modes[0], 'hh:mm A'));
    }

    const getWeekTopInterest = (dates:any)=>{
        const interests = dates?.map((d:any)=>d?.eventGroup).map(((f:any)=>f));
        const eventCount = (interests?.map((i:any)=>i?.map((s:any)=>s?.eventCount)));
        const topEvPerDay = (eventCount?.map((c:any)=>({"index":c.indexOf(Math.max(...c)),'val':Math.max(...c)})));
        const topOfWeekIndex = (topEvPerDay?.map((t:any)=>t?.val).indexOf(Math.max(...topEvPerDay?.map((t:any)=>t.val))));
        const topVal = ((interests[topOfWeekIndex])[(topEvPerDay[topOfWeekIndex]?.index)]);
        return topVal;
    }

    useEffect(() => {
        if(prevWeek.length>0){
            const top = prevWeek?.filter((o:any)=>o?.totalEventCount === Math.max(...prevWeek?.map((o:any) => o?.totalEventCount)));
            const topVal = getWeekTopInterest(prevWeek);
            setPrevWeekTop(top);
            setPrevWeekInterest(topVal?.interestName);
            setPrevWeekPrime(Mode(prevWeek));
        }
    }, [prevWeek]);

    useEffect(() => {
        if(DailyEventsAnalytics.length>0){
            const top = DailyEventsAnalytics?.slice(-7).filter((o:any)=>o?.totalEventCount === (Math.max(...DailyEventsAnalytics?.slice(-7)?.map((o:any) => o.totalEventCount))));
            const topVal = getWeekTopInterest(DailyEventsAnalytics?.slice(-7));
            setCurrWeekTop(top);
            setCurrWeekInterest(topVal?.interestName);
            setCurrWeekPrime(Mode(PrimeTimeAnalytics?.slice(-7)));
        }
    }, [DailyEventsAnalytics]);
    return(
        <div className="component-container" style={{height:'45vh'}}>
            <div className="header">
                <h1 className="title">
                    EVENTS ANALYTICS
                    {/* <p className="sub-title">previous 2 days processing</p> */}
                </h1>
                <Spin className="spin-loading" spinning={isFetching}/>
            </div>
            <div style={{marginBottom:'16px'}}>
                <Button className={selectedGraph===0?"tab-selected":"tab"} onClick={()=>setSelectedGraph(0)}>Day</Button>
                <Button className={selectedGraph===1?"tab-selected":"tab"} onClick={()=>setSelectedGraph(1)}>Time</Button>
                <Button className={selectedGraph===2?"tab-selected":"tab"} onClick={()=>setSelectedGraph(2)}>Interest</Button>
            </div>
            <Divider className="divider"/>
            <div className="analytics-bottom">
                <div className="options">
                    <div className={selectedGraph===0?"analytic-option-selected":"analytic-option"} onClick={()=>setSelectedGraph(0)}>
                        <div className="top">
                            <div className="white-sq">
                                <img src={selectedGraph===0?SunBIcon:SunIcon}/>
                            </div>
                            <div className="data">
                                <h1>
                                    {moment(currWeekTop[0]?.date).format("dddd")}
                                </h1>
                                <h4>PEAK DAY</h4>
                            </div>
                        </div>
                        <p>last week was <span className="blue-text">{moment(prevWeekTop[0]?.date).format("dddd")}</span></p>
                    </div>
                    <div className={selectedGraph===1?"analytic-option-selected":"analytic-option"} onClick={()=>setSelectedGraph(1)}>
                        <div className="top">
                            <div className="white-sq">
                                <img src={selectedGraph===1?ClockBIcon:ClockIcon}/>
                            </div>
                            <div className="data">
                                <h1>
                                    {currWeekPrime &&(
                                        <>
                                            {moment(currWeekPrime).format("h:mm A")} - {moment(currWeekPrime).add(1,"hour").format("hh:mm A")}
                                        </>
                                    )}
                                </h1>
                                <h4>PRIME TIME</h4>
                            </div>
                        </div>
                        {prevWeekPrime &&(
                                <p>last week was <span className="blue-text">
                                    {moment(prevWeekPrime).format("h:mm A")} - {moment(prevWeekPrime).add(1,"hour").format("hh:mm A")}
                                </span></p>
                        )}
                    </div>
                    <div className={selectedGraph===2?"analytic-option-selected":"analytic-option"} onClick={()=>setSelectedGraph(2)}>
                        <div className="top">
                            <div className="white-sq">
                                <img src={selectedGraph===2?InterestsBIcon:InterestsIcon}/>
                            </div>
                            <div className="data">
                                <h1>
                                    {currWeekInterest??""}
                                </h1>
                                <h4>TOP INTERESTS</h4>
                            </div>
                        </div>
                        <p>last week was <span className="blue-text">{prevWeekInterest??""}</span></p>
                    </div>
                </div>
                {(isFetching)?(
                    <Spin className="spin-loading" spinning={isFetching}/>
                ):(
                    <div className="analytics-data">
                        {selectedGraph===0 && <Day data={DailyEventsAnalytics.slice(-7)}/>}
                        {selectedGraph===1 && <Time data={PrimeTimeAnalytics} primeTime={currWeekPrime}/>}
                        {selectedGraph===2 && <Interest data={DailyEventsAnalytics.slice(-7)}/>}
                    </div>
                )}

            </div>
            {/* <div className="construction">
                Oops! This feature is under construction.
            </div> */}
        </div>
    )
}

const mapStateToProps = (state: {
    auth: any;
    userIsBack: boolean;
    googleAccessToken: string;
  }) => ({
    userIsBack: state.userIsBack,
    googleAccessToken: state.auth?.googleAccessToken,
  });
export default connect(mapStateToProps, {
    getEventsDaily,
    getPrimetime,
})(EventsAnalytics);
