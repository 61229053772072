import GoogleCircle from '../../assets/images/logos/icon-google.svg';
import BreezeWhite from '../../assets/images/logos/icon-breeze-white.svg';
import BreezeBlue from '../../assets/images/logos/icon-breeze-blue.svg';
import { EventProps, EventWrapperProps, ToolbarProps, Views } from 'react-big-calendar';
import moment from 'moment';
import { createCalendarEvent } from '../../actions/calendar';
import { Button, Dropdown, Menu, Popover, Spin, Tooltip, Avatar, message } from 'antd';
import {
    PlusCircleFilled,
    LeftOutlined,
    RightOutlined,
    DownOutlined,
} from "@ant-design/icons";
import { ReactNode, useState } from 'react';
import MiniCalendar from './MiniCalendar';
import { contactsColors } from "../../helpers/contactsColors";

export const slotPropGetter = () => {
    return {
        className: 'slot',
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100px',
            fontSize: '14px',
            fontWeight: '700',
            margin: '0px',
            padding: '0px',
        },
    };
};

interface CalendarToolBarProps extends ToolbarProps {
    isLoading?: boolean,
    setDateFilter?: any,
    dateFilter?: any,
    setCalView?: any,
    setView?: any,
    widthSize : number;
}

export const CalendarToolBar: React.FC<CalendarToolBarProps> = ({ date, view, label, onView, localizer, isLoading,
    onNavigate, setDateFilter, dateFilter, setCalView, setView,widthSize }) => {
    let isMobile = widthSize < 992;
    return (
        <div className="calendarToolbar">
            <div className="left-side">
                <div className="nav-btn">
                    <Button onClick={() => onNavigate("PREV")} disabled={isLoading}>
                        <LeftOutlined />
                    </Button>
                    <Button onClick={() => onNavigate("NEXT")} disabled={isLoading}>
                        <RightOutlined />
                    </Button>
                </div>
                <Popover
                    placement="bottomLeft"
                    content={<MiniCalendar setDateFilter={setDateFilter} dateFilter={dateFilter} />}
                    trigger={isLoading ? "" : "click"}
                >
                    <Button className="mini-calendar-btn">
                        <h2>
                            {moment(date, "YYYY MM DD").format("MMMM YYYY")}{" "}
                            <Spin spinning={isLoading} size="small">
                                <DownOutlined
                                    style={{ visibility: isLoading ? "hidden" : "visible" }}
                                />
                            </Spin>
                        </h2>
                    </Button>
                </Popover>
                <div className="date-small-screen">
                    <div className="nav-btn2" style={{display: view==="month"?'flex':'none'}}>
                        <Button onClick={() => onNavigate("PREV")} disabled={isLoading}>
                            <LeftOutlined />
                        </Button>
                    </div>
                    <h3 className='date-full'>{moment(date).format(view === "month" ? "MMMM YYYY" : "DD MMMM YYYY")}
                        <Spin spinning={isLoading} size="small" className='spin' />
                    </h3>
                    <div className="nav-btn2" style={{display: view==="month"?'flex':'none'}}>
                        <Button onClick={() => onNavigate("NEXT")} disabled={isLoading}>
                            <RightOutlined />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="right-side">
                {/* {
      Array.isArray(recommendedEvents) && recommendedEvents.length > 0 && (
      <Button
        className="recommend-btn"
        onClick={() =>{
          setEventData(recommendedEvents);
          setEventListIsVisible(true);
        }}
      >
        <StarOutlined />
        <span className="label">Recommended Events</span>
      </Button>
      )
    } */}

                <Dropdown
                    className="dropdown"
                    disabled={isLoading}
                    overlay={
                        <Menu>
                            <Menu.Item
                                key={Views.MONTH}
                                onClick={() => {
                                    onView(Views.MONTH);
                                    setView(Views.MONTH);
                                    setCalView("month");
                                }}
                            >
                                Month
                            </Menu.Item>
                            <Menu.Item
                                key={Views.WEEK}
                                onClick={() => {
                                    onView(isMobile ? Views.DAY : Views.WEEK);
                                    setView(isMobile ? Views.DAY : Views.WEEK);
                                    setCalView(isMobile ? "day" : "week");
                                }}
                            >
                                Week
                            </Menu.Item>
                            {!isMobile &&
                                <Menu.Item
                                    key={Views.DAY}
                                    onClick={() => {
                                        onView(Views.DAY);
                                        setView(Views.DAY);
                                        setCalView("day");
                                    }}
                                >
                                    Day
                                </Menu.Item>
                            }
                        </Menu>
                    }
                >
                    <Button>
                        {(isMobile && view === "day") ? "Week" : view.charAt(0).toUpperCase() + view.slice(1)} <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
};

export const DayHeader = ({ date, localizer }: { date: any, localizer: any }) => {
    return (
        <div className={"day-header-cell"}>
            <h4>{localizer.format(date, 'ddd')}</h4>
            <h1>{localizer.format(date, 'DD')}</h1>
        </div>
    )
};

export const WeekHeader = ({ date, localizer }: { date: any, localizer: any }) => {
    return (
        <div className={"week-header-cell"}>
            <h4>{localizer.format(date, 'ddd')}</h4>
            <h1>{localizer.format(date, 'DD')}</h1>
        </div>
    )
};

interface IEventComponent {
    // Add any additional props you may need
    event: any;
    refresh: () => void;
}

export const EventComponent: React.FC<IEventComponent> = ({ event, refresh }) => {
    let isUpcomingEvent = (moment(event.actualStart ?? event.start).isAfter(new Date()));
    const [isLoading, setIsLoading] = useState(false);
    const googleAccessToken = localStorage.getItem("googleAccessToken");

    return (
        <div className='event-component' style={getStyling(event)}>
            <span className="upper">
                <img src={event.isAddedEvent ? BreezeWhite : event.isGoogleEvent ? GoogleCircle : BreezeBlue} />
                <span className="details">
                    <p className="title">{event?.title}</p>
                    <p className="time">{moment(event?.actualStart ?? event?.start).format("h:mm a")} - {moment(event?.actualStart ?? event?.start).isSame(event?.actualEnd ?? event?.end, 'day') ?
                        moment(event?.actualEnd ?? event?.end).format("h:mm a")
                        : moment(event?.actualEnd ?? event?.end).format("MMM D, h:mm a")}</p>
                </span>
            </span>
            <div className="lower">
                {!event.isGoogleEvent && isUpcomingEvent &&
                    <Spin spinning={isLoading}>
                        <PlusCircleFilled className="addIcon" onClick={async (e) => {
                            e.stopPropagation();
                            try {
                                setIsLoading(true);
                                const res = await createCalendarEvent({
                                    accessToken: googleAccessToken,
                                    eventId: event.eventId ?? event?.conflicts[0]
                                });
                                let call = await res({});
                                if (call.status === 200) {
                                    message.success(`You successfully added ${event?.title}!`);
                                    refresh();
                                }
                                setIsLoading(false);
                            }
                            catch (e) { 
                                message.error('An Error Occured');
                             }
                        }
                        } />
                    </Spin>
                }
                {
                    event.conflicts !== undefined && (<p className={event.isGoogleEvent?"more-w":"more"}>+ {event.conflicts?.length - 1} <u>More</u></p>)
                }
            </div>
        </div>
    )
};

interface IEventComponentMonth {
    isAddedEvent: any;
    isGoogleEvent: any;
    count: any;
    event: any;
}

export const EventComponentMonth: React.FC<EventProps<IEventComponentMonth>> = ({ event }) => {
    return (
        <div className='event-component-month'  style={getStyling(event)}>
            {window.innerWidth>=992 && 
                <img src={event.isAddedEvent ? BreezeWhite : event.isGoogleEvent ? GoogleCircle : BreezeBlue} />
            }
            <p >
                {event.count} event{event.count > 1 ? "s" : ''}
            </p>
        </div>
    )
};

interface ICustomEventWrapper {
    event: any;
    calView: any;
    allEventItems: any;
    refresh: Function;
}

export const CustomEventWrapper: React.FC<ICustomEventWrapper> = ({ event, children, calView, allEventItems, refresh }) => {
    // Your custom event wrapper logic
    return (
        <Tooltip
            title={(window.innerWidth<992)?"":<CustomTooltip type={"event"} data={event} calView={calView} allEventItems={allEventItems} refresh={refresh}/>}
            className="overlap-tooltip"
            color="#F3F6F9"
        >
            {children}
        </Tooltip>
    );
};

export const CustomTooltip = ({ type, data, calView, allEventItems, refresh }: { type: any, data: any, calView: any, allEventItems: any, refresh: Function; }) => {
    const googleAccessToken = localStorage.getItem("googleAccessToken");

    let conflicts = [];
    if (data.conflicts !== undefined) {
        conflicts = data.conflicts;
    } else if (data.classes !== undefined) {
        conflicts = data.classes;
    }

    const EventBox = (event:any) => {
        const [isEventLoading, setIsEventLoading] = useState(false);
        let isUpcomingEvent = (moment(event.start).isAfter(new Date()));
        return (<p className="event-details">
            <div className="data">
                <h4>
                    <img src={BreezeBlue}/>
                    {event.title}
                </h4>
                <span className="time">
                    <p>{moment(event.start).format(
                        "h:mm A"
                    )}
                        - {moment(event.end).format(
                            "h:mm A"
                        )}
                    </p>
                </span>
                {
                    event.mutual&&event.mutual.length>0
                    ?(
                        <Avatar.Group
                        maxCount={3}
                        // maxPopoverTrigger="click"
                        maxPopoverTrigger="hover"
                        size={18}
                        maxStyle={{ color: '#0098EE', backgroundColor: '#D3F4FF', cursor: 'pointer', pointerEvents:'none' }}
                        className="avatar-group"
                        >
                        {event.mutual?.map((s:any)=>
                            <Tooltip title={s.displayName} placement="top">
                            <Avatar style={{ backgroundColor: contactsColors[Math.floor(Math.random() * 6)] }} src={s.profilePicture??null}>{s.displayName.match(/\b(\w)/g).join('')}</Avatar>
                            </Tooltip> 
                        )}
                        </Avatar.Group>
                    )
                    :(
                        <span className="mutuals">No mutual(s)</span>
                    )
                }
            </div>
            {(!event.isGoogleEvent)&&(event.metadata !== null)&& isUpcomingEvent && (
                <div className="add-event-spin">
                <Spin spinning={isEventLoading}>
                    <PlusCircleFilled className="add-event-btn" onClick={async (e) => {
                            e.stopPropagation();
                            try {
                                setIsEventLoading(true);
                                const res = await createCalendarEvent({
                                    accessToken: googleAccessToken,
                                    eventId: event.eventId
                                });
                                let call = await res({});
                                if (call.status === 200) {
                                    message.success(`You successfully added ${event?.title}!`);
                                    refresh();
                                }
                                setIsEventLoading(false);
                            }
                            catch (e) { 
                                message.error('An Error Occured');
                            }
                        }
                    } />
                </Spin>
                </div>
            )}
        </p>)
    }

    return (
        <div className="custom-tooltip">
            {calView !== "month" &&
                type === "event" &&
                data.conflicts === undefined && (
                    <>
                        <h3>{data.title}</h3>
                        <p>
                            {moment(data.actualStart ?? data.start).format("dddd, MMMM D h:mm a ")} -{" "}
                            {moment(data.actualStart ?? data.start).isSame(data.actualEnd ?? data.end, "day")
                                ? moment(data.actualEnd ?? data.end).format("h:mm a")
                                : moment(data.actualEnd ?? data.end).format("dddd, MMMM D h:mm a")}
                            <br />
                        </p>
                    </>
                )
            }
            {
                type === "event" && (conflicts.length > 0) && (
                    <>
                        <h3 className="conflict-time">{moment(data.start).format("dddd D - ha")} {data.isAddedEvent?"Added":data.isGoogleEvent?"Google":"Breeze"} Events </h3>
                        <p className="conflict-list">
                            {conflicts?.map((c: any) => { 
                                let event = allEventItems.filter((a: any) => a.eventId === c)[0];
                                return(
                                    <EventBox {... event}/>
                                )
                            }
                            )}
                        </p>
                    </>
                )}
        </div>
    );
};

export const getStyling = (event: any) => {

    let eventProps: any = {};

    if (event.isAddedEvent) {
        eventProps.style = {
            color: '#FFF',
            borderColor: '#02B8F6',
            backgroundColor: '#02B8F6', borderRadius: "5px",
            filter: moment(event.actualStart ?? event.start).isAfter(new Date()) ? null : 'opacity(50%) brightness(100%)',
        };
    } else if (event.isClass || event.isGoogleEvent) {
        eventProps.style = {
            color: '#FFF',
            borderColor: '#859CA1',
            backgroundColor: '#859CA1', borderRadius: "5px",
            filter: moment(event.actualStart ?? event.start).isAfter(new Date()) ? null : 'opacity(50%) brightness(100%)',
        };
    } else {
        eventProps.style = {
            color: '#595959',
            borderColor: '#D3F4FF',
            backgroundColor: '#D3F4FF',
            borderRadius: "5px",
            filter: moment(event.actualStart ?? event.start).isAfter(new Date()) ? null : 'opacity(50%) brightness(100%)',
        };
    }
    return eventProps.style;
}


interface IEventComponentDay {
    event: any;
    refresh: any;
}

export const EventComponentDay: React.FC<IEventComponentDay> = ({ event, children, refresh }) => {
    const googleAccessToken = localStorage.getItem("googleAccessToken");
    let isUpcomingEvent = (moment(event.actualStart ?? event.start).isAfter(new Date()));
    let isMultiDay = (moment(event.actualStart ?? event.start).day() !== (moment(event.actualEnd ?? event.end).day()));
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className='d-flex w-100 h-100' style={{ gap: "6px" }}>
            {event?.neighborCount>1 && event.conflicts !== undefined && !event.isAddedEvent && (
                    <div className='d-flex' style={{
                        color: '#595959',
                        borderColor: '#D3F4FF',
                        backgroundColor: '#D3F4FF', justifyContent: "center", alignItems: "center",
                        // minWidth: "100px",
                        width:'100%',
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                    }}> <p className="more" style={{ fontWeight: "bold", color: "#0098EE", margin: 0 }}>+ {event.conflicts?.length - 1} More</p>
                    </div>)
            }
            { (event?.neighborCount===undefined || event?.neighborCount<=1) &&
            <div className={`event-component-day ${isMultiDay && "multi"} w-100`} style={getStyling(event)}>
                <span className="upper">
                    <img src={event.isAddedEvent ? BreezeWhite : event.isGoogleEvent ? GoogleCircle : BreezeBlue} />
                    <span className="details">
                        <p className="title">{event.title}</p>
                        {
                            !isMultiDay && (
                                <p className="time">{moment(event?.actualStart ?? event?.start).format("h:mm a")} - {moment(event?.actualStart ?? event?.start).isSame(event?.actualEnd ?? event?.end, 'day') ?
                                    moment(event?.actualEnd ?? event?.end).format("h:mm a")
                                    : moment(event?.actualEnd ?? event?.end).format("MMM D, h:mm a")}</p>
                            )
                        }
                    </span>
                </span>
                <div className="lower">
                    {!event.isGoogleEvent && isUpcomingEvent &&
                        <Spin spinning={isLoading}>
                            <PlusCircleFilled className="addIcon" onClick={async (e) => {
                                e.stopPropagation();
                                try {
                                    setIsLoading(true);
                                    const res = await createCalendarEvent({
                                        accessToken: googleAccessToken,
                                        eventId: event.eventId ?? event?.conflicts[0]
                                    });
                                    let call = await res({});
                                    if (call.status === 200) {
                                        refresh();
                                        message.success(`You successfully added ${event?.title}!`);
                                    }
                                    setIsLoading(false);
                                }
                                catch (e) { 
                                    message.error('An Error Occured');
                                }
                            }
                            } />
                        </Spin>
                    }
                    {
                        event.conflicts !== undefined && event.conflicts?.length > 1 && (<p className={event.isGoogleEvent?"more-w":"more"}>+ {event.conflicts?.length - 1} <u>More</u></p>)
                    }
                </div>
            </div>
            }
        </div>
    )
};