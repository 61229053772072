import { ReactNode, useState } from "react";
import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Space,
  Tag,
  Input,
  Modal,
} from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import InterestsModal from "./InterestsModal";
import EventTagsModal from "./EventTagsModal";
import PrimeTimeModal from "./PrimeTimeModal";
import PersonAdd from "../../../assets/images/icons/person_add.svg";
import ModalImportContacts from "../../layout/ModalImportContacts";
import { setRefreshCalendar } from "../../../actions/refreshCalendar";

const { CheckableTag } = Tag;
const { Search } = Input;
const PageMiniHeader = (props: {
  filterToggle: ReactNode;
  children: ReactNode;
  dateRange?: ReactNode | string;
  tags: any;
  interests: any;
  selectedTime: string;
  setSelectedTime: Function;
  selectedTags: any;
  setSelectedTags: Function;
  selectedInterest: any;
  setSelectedInterest: Function;
  setRefreshCalendar: Function;
}) => {
  const {
    filterToggle,
    dateRange,
    children,
    tags,
    interests,
    selectedTime,
    setSelectedTime,
    selectedTags,
    setSelectedTags,
    selectedInterest,
    setSelectedInterest,
    setRefreshCalendar,
  } = props;
  const [searchString, setSearchString] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInterestsOpen, setIsModalInterestsOpen] = useState(false);
  const [isModalTimeOpen, setIsModalTimeOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isImportContactsModalOpen, setIsImportContactsModalOpen] =
    useState(false);

  const times = [
    { name: "Morning", slot: "5:00 am - 11:59 am" },
    { name: "Afternoon", slot: "12:00 pm - 5:59 pm" },
    { name: "Night", slot: "6:00 pm - 11:59 pm" },
  ];

  const handleChange = (tag: string, checked: boolean) => {
    if (selectedTags.length > 4 && checked == true) {
      tagError();
    } else {
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t: any) => t !== tag);
      setSelectedTags(nextSelectedTags);
    }
  };

  const handleChangeInterest = (interest: string, checked: boolean) => {
    // if(selectedInterest.length>4 && checked==true){
    //   tagError();
    // }else{
    const nextSelectedInterests = checked
      ? [...selectedInterest, interest]
      : selectedInterest.filter((t: any) => t !== interest);
    setSelectedInterest(nextSelectedInterests);
    // }
  };

  const tagError = () => {
    messageApi.open({
      type: "error",
      content: "Maximum of 5 tags only!",
    });
  };

  const InterestFilter = () => {
    return (
      <div className="tag-filter">
        <label className="label" onClick={() => setIsModalInterestsOpen(true)}>
          <h3>Interest</h3>
        </label>
        <div className="tag-input">
          <div className="left-area">
            <Button
              className="searchbtn"
              onClick={() => setIsModalInterestsOpen(true)}
            >
              <SearchOutlined />
            </Button>
            <Space size={[0, 8]}>
              {selectedInterest.slice(0, 2).map((i: any) => (
                <CheckableTag
                  key={i?.interestId}
                  checked={selectedInterest.includes(i)}
                  onChange={(checked) => handleChangeInterest(i, checked)}
                >
                  {i?.name}
                </CheckableTag>
              ))}
              {selectedInterest.length > 2 && (
                <Tag onClick={() => setIsModalInterestsOpen(true)}>
                  +{selectedInterest.length - 2}
                </Tag>
              )}
            </Space>
            {selectedInterest.length === 0 && (
              <p className="placeholder">Select one or more interests</p>
            )}
          </div>
          {selectedInterest.length > 0 && (
            <Button
              className="closebtn"
              onClick={() => setSelectedInterest([])}
            >
              <CloseCircleOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  };

  const TagFilter = () => {
    return (
      <div className="tag-filter">
        <label className="label" onClick={() => setIsModalOpen(true)}>
          <h3>Event Tags</h3>
          {/* <p>Select Event Tags</p> */}
        </label>
        {selectedTags.length === 0 ? (
          <div className="tag-input-r">
            <p>Select one or more tags</p>
            <Button className="searchbtn" onClick={() => setIsModalOpen(true)}>
              <SearchOutlined />
            </Button>
          </div>
        ) : (
          <div className="tag-input">
            <Space size={[0, 8]}>
              {selectedTags.slice(0, 2).map((t: any) => (
                <CheckableTag
                  key={t.eventTagId}
                  checked={selectedTags.includes(t)}
                  onChange={(checked) => handleChange(t, checked)}
                >
                  {t.name}
                </CheckableTag>
              ))}
              {selectedTags.length > 2 && (
                <Tag onClick={() => setIsModalOpen(true)}>
                  +{selectedTags.length - 2}
                </Tag>
              )}
            </Space>
            <Button className="closebtn" onClick={() => setSelectedTags([])}>
              <CloseOutlined />
            </Button>
            <Button className="searchbtn" onClick={() => setIsModalOpen(true)}>
              <SearchOutlined />
            </Button>
          </div>
        )}
      </div>
    );
  };

  const TimeFilter = () => {
    return (
      <div className="time-filter">
        <label className="label" onClick={() => setIsModalTimeOpen(true)}>
          <h3>Preferred Time</h3>
        </label>
        <div className="tag-input">
          <Button
            className="searchbtn"
            onClick={() => setIsModalTimeOpen(true)}
          >
            <ClockCircleOutlined />
          </Button>
          {selectedTime === "" ? (
            <p className="placeholder">Preferred time to attend</p>
          ) : (
            <Space size={[0, 8]} wrap>
              <CheckableTag
                checked={true}
                // checked={times.includes(t)}
                // onChange={(checked) => handleChange(t, checked)}
              >
                {selectedTime}
              </CheckableTag>
            </Space>
          )}
          {selectedTime !== "" && (
            <Button className="closebtn" onClick={() => setSelectedTime("")}>
              <CloseCircleOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="mini-page-header">
      {contextHolder}
      <div className="container">
        <div className="header-wide">
          <div className="list-filters mr-4">
            <InterestFilter />
          </div>
          {/* <div className="list-filters mr-4"><InterestFilter/></div> */}
          {/* <div className="list-filters"><TagFilter/></div> */}
          {/* <div className="list-filters"><TimeFilter/></div> */}
          {/* <div className="list-filters">{filterToggle}</div> */}
          {/* <div>{dateRange}</div> */}
          {/* <Button
            type="primary"
            shape="round"
            className="add-contact-btn"
            onClick={() => setIsImportContactsModalOpen(true)}
          >
            <img src={PersonAdd} />
          </Button> */}
        </div>
        <div className="header-compressed">
          <InterestFilter />
          {/* <Button
            type="primary"
            shape="round"
            className="add-contact-btn"
            onClick={() => setIsImportContactsModalOpen(true)}
          >
            <img src={PersonAdd} />
          </Button> */}
          {/* <Button
              className="mobile-reactive-btn"
              icon={<TagsOutlined />}
              shape="round"
              onClick={()=> setIsModalOpen(true)}
            /> */}
          {/* <Button
              className={selectedTime!==""?"time-selected-btn":"mobile-reactive-btn"}
              icon={<ClockCircleOutlined />}
              shape="round"
              onClick={()=> setIsModalTimeOpen(true)}
            /> */}
        </div>
        {/* <div className="list-filters">{props.children}</div> */}
      </div>
      <InterestsModal
        interests={interests}
        isModalOpen={isModalInterestsOpen}
        setIsModalOpen={setIsModalInterestsOpen}
        setSelectedInterest={setSelectedInterest}
        selectedInterest={selectedInterest}
      />
      <EventTagsModal
        tags={tags}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setSelectedTags={setSelectedTags}
        selectedTags={selectedTags}
      />
      <PrimeTimeModal
        timeSlots={times}
        isModalOpen={isModalTimeOpen}
        setIsModalOpen={setIsModalTimeOpen}
        setSelectedTime={setSelectedTime}
        selectedTime={selectedTime}
      />
      <ModalImportContacts
        visible={isImportContactsModalOpen}
        onCancel={(isContactUpdated: boolean) => {
          if (isContactUpdated) setRefreshCalendar(isContactUpdated);
          setIsImportContactsModalOpen(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: {
  auth: any;
  userIsBack: boolean;
  googleAccessToken: string;
}) => ({
  member: state.auth?.member,
  googleAccessToken: state.auth?.googleAccessToken,
});

export default connect(mapStateToProps, { setRefreshCalendar })(PageMiniHeader);
