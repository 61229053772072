import { callApi } from './general';

const baseUrl = '/api/Analytics';

//OPAC
export const getOverview = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
    { name: 'filterBy' },
    { name: 'previousMonthFrom' },
    { name: 'previousMonthTo' },
  ];
  return await callApi({
    api: 'event-overview',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const getEventViews = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
    { name: 'limit' }
  ];
  return await callApi({
    api: 'event-views',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const getPopularInterests = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
    { name: 'limit'}
  ];
  return await callApi({
    api: 'popular-interests',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const getPopularLocations = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
    { name: 'limit'}
  ];
  return await callApi({
    api: 'popular-locations',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const getPopularClubs = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
  ];
  return await callApi({
    api: 'popular-clubs',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

//Poorvu
export const getLoginOverview = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
    { name: 'FilterBy' },
  ];
  return await callApi({
    api: 'login-overview',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const getWeeklyLoginOverview = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
    { name: 'FilterBy' },
  ];
  return await callApi({
    api: 'weekly-login-overview',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const getEventsDaily = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
  ];
  return await callApi({
    api: 'events-daily',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const getPrimetime = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'from' },
    { name: 'to' },
  ];
  return await callApi({
    api: 'primetime',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};
