import {
  REGISTER_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_TOKEN,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  refreshToken: localStorage.getItem("refreshToken"),
  googleAccessToken: localStorage.getItem("googleAccessToken"),
  isAuthenticated: null,
  loading: true,
  member: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      console.log('user load');
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        member: payload,
      };
    case UPDATE_PROFILE_SUCCESS:
      // console.log('update profile');
      return {
        ...state,
        member: payload,
      };
    case REGISTER_SUCCESS: //same behaviour
    case LOGIN_SUCCESS: //same behaviour
      localStorage.setItem("token", payload.token);
      localStorage.setItem("refreshToken", payload.refreshToken);
      localStorage.setItem("googleAccessToken", payload.googleAccessToken);

      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_ERROR: //WILL DO THE SAME
    case LOGIN_FAIL: //WILL DO THE SAME
    case LOGOUT: //WILL DO THE SAME
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("googleAccessToken");

      return {
        ...state,
        displayName: null,
        token: null,
        refreshToken: null,
        googleAccessToken: null,
        isAuthenticated: false,
        loading: false,
        member: null,
        systemUserId: null,
      };
    case UPDATE_TOKEN: //WILL DO THE SAME
      localStorage.setItem("token", payload.token);
      localStorage.setItem("refreshToken", payload.refreshToken);
      localStorage.setItem("googleAccessToken", payload.googleAccessToken);

      return {
        ...state,
        token: payload.token,
        refreshToken: payload.refreshToken,
        googleAccessToken: payload.googleAccessToken,
      };
    default:
      return state;
  }
}
