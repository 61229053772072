import { useState } from "react";
import { Space, Dropdown, Menu, DatePicker, DatePickerProps } from "antd";
import moment from "moment";
import {
    DownOutlined,
    ArrowLeftOutlined,
  } from "@ant-design/icons";
import { RangePickerProps } from "antd/lib/date-picker";
const { RangePicker } = DatePicker;

const DateFilter=({ date, select, setFilterType }: { date: any, select: Function, setFilterType: Function })=>{
    var selections = [ "Week", "Month", "Custom Range" ]
    const [ selected, setSelected ] = useState("");

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        var from;
        var to;
        if(selected==='Week'){
            from = moment(date).startOf("week").utc().toISOString();
            to = moment(date).endOf("week").utc().toISOString();
        }else{
            from = moment(date).startOf("month").utc().toISOString();
            to = moment(date).endOf("month").utc().toISOString();
        }
        select([from,to]);
        setFilterType(selected);
    };
    const onChangeRange = (
        value: DatePickerProps["value"] | RangePickerProps["value"],
        dateString: [string, string] | string
      ) => {
        select([moment(dateString[0]).utc().toISOString(), moment(dateString[1]).utc().toISOString()]);
        setFilterType(selected);
    };
    
    return(
        <div className="date-filter">
            {selected===""?(
                <span style={{color:'#000'}}>Sort by:</span>
            ):(
                <span><ArrowLeftOutlined onClick={()=>setSelected("")}/> Select {selected}</span>
            )}
            {selected===""?(
                <div className="filter-dropdown">
                    <Dropdown
                        overlay={
                            <Menu>
                                {selections.map((m,i)=>
                                    <Menu.Item key={i}>
                                        <a onClick={()=>setSelected(m)}>{m}</a>
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                        >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            Select
                            <DownOutlined />
                        </Space>
                    </a>
                    </Dropdown>
                </div>
            ):(
                selected!=='Custom Range'?(
                    <DatePicker className="date-picker" dropdownClassName="date-picker-dropdown" picker={selected==="Week"?"week":'month'} onChange={onChange}/>
                ):(
                    <RangePicker className="date-picker" dropdownClassName="date-picker-dropdown" onChange={onChangeRange}/>
                )
            )}
        </div>
    )
}

export default DateFilter;
