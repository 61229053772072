import { Row, Col, Tooltip, Progress } from "antd";
import moment from "moment";

const CustomTooltip = (data:any)=>{
    return(
        <div className="analytics-tooltip">
            <div className="title">
                {/* ${tooltipModel.title} */}
            </div>
            <div className="total" style={{marginBottom:"10px"}}><span>Events</span><span>{Number(data.raw)}</span></div>
            <div className="summary-info">
                <div className="left">
                    <div className="label">
                        <span className="point" style={{backgroundColor:"#A155B9"}}></span>
                        <span>Sample 1</span>
                    </div>
                </div>
                <p className="point-total" style={{color:"#A155B9"}}>{data.formattedValue}</p>
            </div>
            <div className="summary-info">
                <div className="left">
                    <div className="label">
                        <span className="point" style={{backgroundColor:"#F765A3"}}></span>
                        <span>Sample 2</span>
                    </div>
                </div>
                <p className="point-total" style={{color:"#F765A3"}}>{data.formattedValue}</p>
            </div>
            <div className="summary-info">
                <div className="left">
                    <div className="label">
                        <span className="point" style={{backgroundColor:"#165BAA"}}></span>
                        <span>Sample 3</span>
                    </div>
                </div>
                <p className="point-total" style={{color:"#165BAA"}}>{data.formattedValue}</p>
            </div>
            <div className="summary-info">
                <div className="left">
                    <div className="label">
                        <span className="point" style={{backgroundColor:"#E74731"}}></span>
                        <span>Sample 3</span>
                    </div>
                </div>
                <p className="point-total" style={{color:"#E74731"}}>{data.formattedValue}</p>
            </div>
            <div className="summary-info">
                <div className="left">
                    <div className="label">
                        <span className="point" style={{backgroundColor:"#FFA800"}}></span>
                        <span>Sample 3</span>
                    </div>
                </div>
                <p className="point-total" style={{color:"#FFA800"}}>{data.formattedValue}</p>
            </div>
        </div>
    )
}
export default CustomTooltip;