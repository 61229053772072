import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Col, Spin, Row, Tooltip } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import EventIcon from '../../../../assets/images/icons/pivot-admin/Event.svg'
import InterestsIcon from '../../../../assets/images/icons/pivot-admin/Interests.svg'

import { getOverview } from "../../../../actions/pivotAdmin";
import AnalyticTooltip from "./components/AnalyticsTooltip";
import InterestTooltip from "./components/InterestTooltip";

const Overview=(props:{
    dates: any,
    filterType: any,
    getOverview: Function,
})=>{
    const {
        dates,
        filterType,
        getOverview,
      } = props;
    const [ overViewData, setOverviewData ] = useState<any>({});
    const [ isFetching, setIsFetching ] = useState(false);

    const fetchOverview = async() =>{
        setIsFetching(true);
        const defaultFrom = moment().startOf("month").utc().toISOString();
        const defaultTo = moment().endOf("month").utc().toISOString();
        const dataDateFrom = dates[0] ? moment(dates[0]).startOf('day').utc().toISOString() : defaultFrom;
        const dataDateTo = dates[1] ? moment(dates[1]).endOf('day').utc().toISOString() : defaultTo;
        const res = await getOverview({
            from: dataDateFrom,
            to: dataDateTo,
            filterBy : filterType
        });
        if(res.status===200){
            setOverviewData(res.data);
        }
        setIsFetching(false);
    }

    useEffect(() => {
        fetchOverview();
      }, [dates]);

    return(
        <div className="component-container" style={{height:'225px'}}>
            <div className="header">
                <h1 className="title">
                    OVERVIEW
                    <Tooltip title={
                        <div className="overview-info">
                            <h3>Information</h3>
                            This result is based on data from {dates[0]===null?moment().format('MMMM YYYY'):(filterType==='Month' ? moment(dates[0]).format('MMMM YYYY') : `${moment(dates[0]).format('MMM DD')} - ${moment(dates[1]).format('MMM DD')}`)}
                        </div>
                    } placement="bottom" color="#FFF" overlayInnerStyle={{maxWidth: '200px'}} className="overview-tooltip">
                        <i>i</i>
                    </Tooltip>
                </h1>
                <Spin className="spin-loading" spinning={isFetching}/>
            </div>
            <div className="overview">
                <Row gutter={[16, 16]} >
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="section">
                            <Tooltip title={<AnalyticTooltip dates={dates} filterType={filterType} overViewData={overViewData}/>} placement="bottomLeft" color="#FFF" overlayInnerStyle={{maxWidth: '265px'}}>
                                <div className="top">
                                    <div className="white-sq"> 
                                        <img src={EventIcon}/>
                                    </div>
                                    <div className="data">
                                        <h1>{overViewData?.totalEvents ?? "No Data"}</h1>
                                        <p>TOTAL NUMBER OF EVENTS</p>
                                    </div>
                                </div>
                            </Tooltip>
                            {(filterType!=="Custom Range") && (
                                overViewData?.previousTotalEvents && (
                                    <div className="bottom">
                                        <span className={'blue'}>{overViewData?.previousTotalEvents} </span>
                                        total number of events last {filterType.toLowerCase()}
                                    </div>
                                )
                            )}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="section">
                            <Tooltip title={<InterestTooltip dates={dates} filterType={filterType} overViewData={overViewData}/>} placement="bottomLeft" color="#FFF" overlayInnerStyle={{maxWidth: '265px'}}>
                                <div className="top">
                                    <div className="white-sq">
                                        <img src={InterestsIcon}/>
                                    </div>
                                    <div className="data">
                                        <h1>{overViewData?.popularInterest??'No Data'}</h1>
                                        <p>MOST POPULAR INTEREST</p>
                                    </div>
                                </div>
                            </Tooltip>
                            {filterType!=="Custom Range" && (
                                overViewData?.previousTotalEvents && (
                                    <div className="bottom">
                                        <span className="blue">{overViewData?.previousPopularInterest} </span>
                                        was the most popular last {filterType?.toLowerCase()} 
                                    </div>
                                )
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
            {/* <div className="construction-sm">
                Oops! This feature is under construction.
            </div> */}
        </div>
    )
}

const mapStateToProps = (state: {
    auth: any;
    userIsBack: boolean;
    googleAccessToken: string;
  }) => ({
    userIsBack: state.userIsBack,
    googleAccessToken: state.auth?.googleAccessToken,
  });
export default connect(mapStateToProps, {
    getOverview
})(Overview);
