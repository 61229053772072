import { Layout, Button, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import HomePageHeader from "../../components/HomePageHeader/HomePageHeader";
import DetailedFooter from "../../components/layout/DetailedFooter";
import graphic1 from "../../assets/images/homepage-graphics-1.png";
import graphic2 from "../../assets/images/homepage-graphics-2.png";
import graphic3 from "../../assets/images/homepage-graphics-3.png";
import graphic4 from "../../assets/images/homepage-graphics-4.png";
import graphic5 from "../../assets/images/homepage-graphics-5.png";
import googleLoginBtn from "../../assets/images/continue-w-google.svg";
import iconClose from "../../assets/images/icons/icon-close.svg";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BAD_REQUEST, OK } from "../../helpers/statuscodes";
import { Admin, Student, Teacher } from "../../helpers/userRoles";
import { setRecentLogout } from "../../actions/recentLogout";
import { login } from "../../actions/auth";
import { setAlert } from "../../actions/alert"
import ModalUserAgreement from "../LoginPage/ModalUserAgreement";
import { tagLoginDetails } from "../../utils/googleTagManager";

const Homepage = (props: any) => {
  const {
    login,
    firebase,
    auth: { isAuthenticated, member },
    flutterConnect,
    recentLogout,
    setRecentLogout,
  } = props;
  let navigate = useNavigate();
  const [redirect, setRedirect] = useState<string>('');
  const [isAdminRedirect, setIsAdminRedirect] = useState<boolean | null>(null);
  const [modalUAisOpen, setModalUAisOpen] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [validationMessages, setValidationMessages]: any = useState([]);

  const loginGoogle = useGoogleLogin({
    onSuccess: tokenResponse => responseMessage(tokenResponse),
    flow: 'auth-code',
    onError: () => setAlert('Error', 'Login failed.', "error"),
    scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events openid',
    // flow: 'auth-code',
    // ux_mode: flutterConnect ? 'redirect' : 'popup',
    include_granted_scopes: false,
    ux_mode: 'redirect',
    redirect_uri: window.location.origin
    // state: 'test'
  });


  const onAgree = () => {
    setModalUAisOpen(false);
    loginGoogle();
  }

  const responseMessage = async (response: any) => {
    setValidationMessages(new Array()); //clear validation errors
    setIsloading(true);

    const res = await login({
      loginType: 1,
      authCode: response.code,
    });

    if (res.status === OK) {
      let decoded: any = jwt_decode(res.data.token);
      // let userRole: String =
      //   decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        // setUserRole(userRole);
        
      if (res.data.member)
        tagLoginDetails(res.data.member.memberId);
      setIsLoginSuccess(true);
    } else if (res.response.status === BAD_REQUEST) {
      if (
        res.response.data.error ===
        "Your account has been locked due to 5 consecutive unsuccessful login attempts. Try again in 5 minutes."
      ) {
        setModal2Visible(true);
      }
      setValidationMessages({
        message: res.response.data.error,
      });
    }
    setIsloading(false);
  };

  if (isLoginSuccess) {
    if (member && "memberType" in member) {
      if (member.memberType === Student) {
        if (member && member.isOnBoarded) {
          navigate(redirect && !isAdminRedirect ? redirect : "/calendar");    //pivot
        } else {
          navigate(redirect && !isAdminRedirect ? redirect : "/interests");
        }
      } else if (member.memberType === Admin) {
        navigate("/admin");
      }
    } 
  }

  const hideModal = () => {
    setModal2Visible(false);
  };

  useEffect(() => {
    // NOTE: first admin login force redirects to welcome page

    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
    if (Object.keys(searchParams).includes('code'))
      responseMessage(searchParams);

    setRedirect(new URLSearchParams(window.location.search).get('r') ?? '');
    setIsAdminRedirect(redirect?.startsWith('/admin'));
    if (isAuthenticated) {
      if (member && "memberType" in member) {
        if (member.memberType === Student || member.memberType === Teacher) {
          navigate(redirect && !isAdminRedirect ? redirect : "/calendar");    //pivot
        } else {
          navigate(redirect && isAdminRedirect ? redirect : "/admin");
        }
      } else {
        navigate("/admin");
      }
    }
  }, []);

  if (recentLogout) {
    window.history.replaceState({}, '', window.location.pathname);
    setRecentLogout(false);
  }

  return (
    <Layout>
      <ModalUserAgreement
        isOpen={modalUAisOpen}
        setIsOpen={setModalUAisOpen}
        onAgree={onAgree}
      />
      <Modal
          className="modal-notify"
          centered
          visible={modal2Visible}
          onCancel={hideModal}
          footer={[
            <Button key="submit" className="btn-breeze" onClick={hideModal}>
              Confirm
            </Button>,
          ]}
        >
          <div className="d-flex">
            <div>
              <img src={iconClose} />
            </div>
            <div className="d-flex-column ml-2">
              <div className="mod-title">Failed Login</div>
              <div className="mt-1">
                <p className="modal-message">
                  Your account has been locked because of 5 consecutive
                  unsuccessful login attempts. Try again in 5 minutes.
                </p>
              </div>
            </div>
          </div>
        </Modal>
      <HomePageHeader pageindex="1" />
      <Spin spinning={isLoading} size="large" style={{maxHeight:"100%"}}>
      <Content className="homepage-content">
        <div className="content">
          <h1>Breeze</h1>
          <h3>Never miss an event again! Breeze sorts events so you see only what you like, links with your google calendar to eliminate conflicts, and import your contacts to go with your friends.</h3>
          {/* <Button className="login-btn"> */}
            {/* <Link to="/login">Log in</Link> */}
              <div className="google-login-btn" 
                onClick={() => setModalUAisOpen(true)}
              >
                <img src={googleLoginBtn}/>
              </div>
          {/* </Button> */}
        </div>
        <img src={graphic1} className="graphic"/>
      </Content>
      </Spin>
      <div className="homepage-content-2">
        <img src={graphic2} className="graphic"/>
        <div className="content">
          <h3>Your Event Navigator. From campus activities to local surprise events, Breeze crafts a tailored event journey, ensuring you never miss an opportunity to make memories!</h3>
        </div>
      </div>
      <div className="wave-bg">
        <h1>Check out our features!</h1>
        <div className="wave"/>
        <div className="homepage-content-3">
          <div className="content">
            <h2>Calendar View</h2>
            <h3>Stay in the loop at a glance! A calendar view that makes making college memories easier.</h3>
          </div>
          <img src={graphic3} className="graphic"/>
        </div>
        <div className="homepage-content-4">
          <img src={graphic4} className="graphic"/>
          <div className="content">
            <h2>Interest Filter</h2>
            <h3>Tailor your campus experience to your passions! Our interest filter ensures you're part of events that truly inspire you!</h3>
          </div>
        </div>
        <div className="wave"/>
        <div className="homepage-content-3">
          <div className="content">
            <h2>Event Recommendations</h2>
            <h3>Discover events and build connections that matter to YOU.</h3>
          </div>
          <img src={graphic5} className="graphic"/>
        </div>
      </div>
      <DetailedFooter/>
    </Layout>
  );
};

const mapStateToProps = (state: { auth: any; firebase: any; flutterConnect: any; recentLogout: boolean; }) => ({
  auth: state.auth,
  firebase: state.firebase,
  flutterConnect: state.flutterConnect,
  recentLogout: state.recentLogout
});

export default connect(mapStateToProps, { setRecentLogout, login })(Homepage);