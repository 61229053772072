import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Col, Spin, Row, Space, Progress, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { 
    getPopularInterests, 
    getPopularLocations, 
    getPopularClubs 
} from "../../../../actions/pivotAdmin";
import {
    CaretUpOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";
import SortingArrows from '../../../../assets/images/sorting-arrows.svg'

import Info from "./components/Info";
import Info2 from "./components/Info2";

const Popular=(props:{
    dates: any,
    getPopularInterests: Function, 
    getPopularLocations: Function, 
    getPopularClubs: Function, 
})=>{
    const {
        dates,
        getPopularInterests, 
        getPopularLocations, 
        getPopularClubs 
    } = props;
    const [ selectedTab, setSelectedTab ] = useState(0);
    const [eventSort, setEventSort] = useState(-1);
    const [hourSort, setHourSort] = useState(-1);
    const [memberSort, setMemberSort] = useState(-1);
    const [popInterestsData, setPopInterestsData] = useState([]);
    const [popLocationsData, setPopLocationsData] = useState([]);
    const [popClubsData, setPopClubsData] = useState([]);


    const [ isFetching, setIsFetching ] = useState(false);

    const fetchPopular = async() =>{
        setIsFetching(true);
        const defaultFrom = moment().startOf("month").utc().toISOString();
        const defaultTo = moment().endOf("month").utc().toISOString();
        const resInt = await getPopularInterests({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
            limit: 6
        });
        if(resInt.status===200){
            setPopInterestsData((resInt.data?.sort((a: any, b: any) => (b?.noOfEvents) - (a?.noOfEvents))).slice(0,5));
        }
        const resLoc = await getPopularLocations({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
            limit: 6
        });
        if(resLoc.status===200){
            setPopLocationsData((resLoc.data?.sort((a: any, b: any) => (b?.noOfEvents) - (a?.noOfEvents))).slice(0,5));
        }
        const resCLub = await getPopularClubs({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
        });
        if(resCLub.status===200){
            setPopClubsData((resCLub?.data?.sort((a: any, b: any) => (b?.noOfEvents) - (a?.noOfEvents))).slice(0,5));
        }
        setIsFetching(false);
    }

    const sortPopular = () =>{
        if(eventSort===0){
            setPopInterestsData(popInterestsData?.sort((a: any, b: any) => (a?.noOfEvents) - (b?.noOfEvents)));
            setPopLocationsData(popLocationsData?.sort((a: any, b: any) => (a?.noOfEvents) - (b?.noOfEvents)));
            setPopClubsData(popClubsData?.sort((a: any, b: any) => (a?.noOfEvents) - (b?.noOfEvents)));
        }else if(eventSort===1){
            setPopInterestsData(popInterestsData?.reverse());
            setPopLocationsData(popLocationsData?.reverse());
            setPopClubsData(popClubsData?.reverse());
        }else if(hourSort===0){
            setPopInterestsData(popInterestsData?.sort((a: any, b: any) => (a?.noOfHours) - (b?.noOfHours)));
        }else if(hourSort===1){
            setPopInterestsData(popInterestsData?.reverse());
        }else if(memberSort===0){
            setPopClubsData(popClubsData?.sort((a: any, b: any) => (a?.noOfMembers) - (b?.noOfMembers)));
        }else if(memberSort===1){
            setPopClubsData(popClubsData?.reverse());
        }
    }

    const sortEvents=()=>{
        if(eventSort===1){
            setEventSort(0);
        }else{
            setEventSort(eventSort+1)
        }
        setHourSort(-1);
        setMemberSort(-1);
    }
    const sortHours=()=>{
        if(hourSort===1){
            setHourSort(0);
        }else{
            setHourSort(hourSort+1)
        }
        setEventSort(-1);
        setMemberSort(-1);
    }
    const sortMembers=()=>{
        if(memberSort===1){
            setMemberSort(0);
        }else{
            setMemberSort(memberSort+1)
        }
        setEventSort(-1);
        setHourSort(-1);
    }

    useEffect(() => {
        fetchPopular();
    }, [dates]);

    useEffect(() => {
        sortPopular();
    }, [eventSort, hourSort]);

    return(
        <div className="component-container" style={{height:'50vh'}}>
            <div className="header">
                <h1 className="title">
                    POPULAR
                </h1>
                <Spin className="spin-loading" spinning={isFetching}/>
            </div>
            <div>
                <Button className={selectedTab===0?"tab-selected":"tab"} onClick={()=>setSelectedTab(0)}>Interest</Button>
                <Button className={selectedTab===1?"tab-selected":"tab"} onClick={()=>setSelectedTab(1)}>Location</Button>
                <Button className={selectedTab===2?"tab-selected":"tab"} onClick={()=>setSelectedTab(2)}>Clubs</Button>
            </div>
            <div className="popular-container">
                {selectedTab===0?(
                    <Row>
                        <Col span={12}>Name</Col>
                        <Col span={6}><p className="table-label" onClick={sortEvents}>Number of Events
                            {eventSort===-1?<img src={SortingArrows}/>:eventSort===0?<CaretDownOutlined className="sort-arrow"/>:<CaretUpOutlined className="sort-arrow"/>}
                            </p>
                        </Col>
                        <Col span={6}><p className="table-label" onClick={sortHours}>Hours of Event
                            {hourSort===-1?<img src={SortingArrows}/>:hourSort===0?<CaretDownOutlined className="sort-arrow"/>:<CaretUpOutlined className="sort-arrow"/>}
                        </p></Col>
                    </Row>
                ):selectedTab===1?(
                    <Row>
                        <Col span={18}>Name</Col>
                        <Col span={6}><p className="table-label"  onClick={sortEvents}>Number of Events
                        {eventSort===-1?<img src={SortingArrows}/>:eventSort===0?<CaretDownOutlined className="sort-arrow"/>:<CaretUpOutlined className="sort-arrow"/>}</p></Col>
                    </Row>

                ):(
                    <Row>
                        <Col span={12}>Name</Col>
                        <Col span={6}><p className="table-label"  onClick={sortEvents}>Number of Events
                        {eventSort===-1?<img src={SortingArrows}/>:eventSort===0?<CaretDownOutlined className="sort-arrow"/>:<CaretUpOutlined className="sort-arrow"/>}</p></Col>
                        <Col span={6}><p className="table-label" onClick={sortMembers}>Number of Members
                        {memberSort===-1?<img src={SortingArrows}/>:memberSort===0?<CaretDownOutlined className="sort-arrow"/>:<CaretUpOutlined className="sort-arrow"/>}</p></Col>
                        
                    </Row>

                )}
                <div className="popular-list">
                {selectedTab===0?(
                    !isFetching && popInterestsData?.length===0 ?(
                        <p>No Data</p>
                    ):(
                        popInterestsData?.map((s:any)=><Info data={s} selectedTab={selectedTab}/>)
                    )
                ):selectedTab===1?(
                    !isFetching && popLocationsData?.length===0 ?(
                        <p>No Data</p>
                    ):(
                        popLocationsData?.map((s:any)=><Info2 data={s}/>)
                    )
                ):
                (
                    !isFetching && popClubsData?.length===0 ?(
                        <p>No Data</p>
                    ):(
                        popClubsData?.map((s:any)=><Info data={s} selectedTab={selectedTab}/>)
                    )
                )}
                </div>
            </div>
            {/* <div className="construction">
                Oops! This feature is under construction.
            </div> */}
        </div>
    )
}

const mapStateToProps = (state: {
    auth: any;
    userIsBack: boolean;
    googleAccessToken: string;
  }) => ({
    userIsBack: state.userIsBack,
    googleAccessToken: state.auth?.googleAccessToken,
  });
export default connect(mapStateToProps, {
    getPopularInterests, 
    getPopularLocations, 
    getPopularClubs 
})(Popular);