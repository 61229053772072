import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Col, PageHeader, Row, Spin, Tooltip } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { getEventViews } from "../../../../actions/pivotAdmin";
import Event from "./components/Event";
import {
    CaretUpOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";
import SortingArrows from '../../../../assets/images/sorting-arrows.svg'

const Events=(props:{
    dates: any,
    getEventViews: Function,
})=>{
    const {
        dates,
        getEventViews,
    } = props;
    const [dateSort, setDateSort] = useState(-1);
    const [participantSort, setParticipantSort] = useState(-1);
    const [viewSort, setViewSort] = useState(-1);
    const [eventViewsData, setEventViewsData] = useState([]);
    const [ isFetching, setIsFetching ] = useState(false);

    const fetchEventViews = async() =>{
        setIsFetching(true);
        const defaultFrom = moment().startOf("month").utc().toISOString();
        const defaultTo = moment().endOf("month").utc().toISOString();
        const res = await getEventViews({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
            limit: 6
        });
        if(res.status===200){
            setEventViewsData(res?.data);
        }
        setIsFetching(false);
    }
   
    const sortEvents = () =>{
        if(dateSort===0){
            setEventViewsData(eventViewsData?.sort((a: any, b: any) => Date.parse(a?.startDate) - Date.parse(b?.startDate)))
        }else if(dateSort===1){
            setEventViewsData(eventViewsData?.sort((a: any, b: any) => Date.parse(b?.startDate) - Date.parse(a?.startDate)))
        }else if(participantSort===0){
            setEventViewsData(eventViewsData?.sort((a: any, b: any) => (a?.participantsCount) - (b?.participantsCount)))
        }else if(participantSort===1){
            setEventViewsData(eventViewsData?.sort((a: any, b: any) => (b?.participantsCount) - (a?.participantsCount)))
        }else if(viewSort===0){
            setEventViewsData(eventViewsData?.sort((a: any, b: any) => (a?.viewCount) - (b?.viewCount)))
        }else if(viewSort===1){
            setEventViewsData(eventViewsData?.sort((a: any, b: any) => (b?.viewCount) - (a?.viewCount)))
        }
    }

    const sortDate=()=>{
        if(dateSort===1){
            setDateSort(0);
        }else{
            setDateSort(dateSort+1);
        }
        setParticipantSort(-1);
        setViewSort(-1);
    }
    const sortParticipants=()=>{
        if(participantSort===1){
            setParticipantSort(0)
        }else{
            setParticipantSort(participantSort+1)
        }
        setDateSort(-1);
        setViewSort(-1);
    }
    const sortViews=()=>{
        if(viewSort===1){
            setViewSort(0)
        }else{
            setViewSort(viewSort+1)
        }
        setDateSort(-1);
        setParticipantSort(-1);
    }

    useEffect(() => {
        fetchEventViews();
    }, [dates]);
  
    useEffect(() => {
        sortEvents();
    }, [dateSort, participantSort, viewSort]);

    return(
        <div className="component-container" style={{height:'50vh'}}>
            <div className="header">
                <h1 className="title">
                    EVENTS
                </h1>
                <Spin className="spin-loading" spinning={isFetching}/>
            </div>
            <div className="event-container">
                <Row>
                    <Col span={9}>Event</Col>
                    <Col span={4}><p className="table-label" onClick={sortDate}>Date
                        {dateSort===-1?<img src={SortingArrows}/>:dateSort===0?<CaretDownOutlined className="sort-arrow"/>:<CaretUpOutlined className="sort-arrow"/>}
                        </p>
                    </Col>
                    <Col span={6}><p className="table-label" onClick={sortParticipants}>Registered Participants
                        {participantSort===-1?<img src={SortingArrows}/>:participantSort===0?<CaretDownOutlined className="sort-arrow"/>:<CaretUpOutlined className="sort-arrow"/>}
                    </p></Col>
                    <Col span={5}><p className="table-label" onClick={sortViews}>Number of Views
                        {viewSort===-1?<img src={SortingArrows}/>:viewSort===0?<CaretDownOutlined className="sort-arrow"/>:<CaretUpOutlined className="sort-arrow"/>}
                    </p></Col>
                </Row>
                <div className="event-list">
                    {eventViewsData?.length === 0 ? (
                        !isFetching && (
                            <p>No Data</p>
                        )
                    ):(
                        <>
                            {eventViewsData?.map((s:any)=><Event data={s}/>)}
                        </>
                    )}
                </div>
            </div>
            {/* <div className="construction">
                Oops! This feature is under construction.
            </div> */}
        </div>
    )
}

const mapStateToProps = (state: {
    auth: any;
    userIsBack: boolean;
    googleAccessToken: string;
  }) => ({
    userIsBack: state.userIsBack,
    googleAccessToken: state.auth?.googleAccessToken,
  });
export default connect(mapStateToProps, {
    getEventViews,
})(Events);

